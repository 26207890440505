import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useDispatch } from 'react-redux'

import { approvePartnerRequest } from '../../store/actions/socialcommerce'

export default function AlertDialog({
  open,
  setOpen,
  contract,
  handleAction
}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen({open: false, action: null});
  };

  function handleApprovePartner() {
    open.action === 'revoke'
    ? dispatch(handleAction({ id: contract.id, status: 'PENDING' }))
    : dispatch(approvePartnerRequest({
      contractId: contract.id,
      isApproved: open.action === 'approve'
    }))

    handleClose();

    if (open.action === 'revoke') return;
  }

  return (
    <div>
      <Dialog
        open={open?.open}
        onClose={handleClose}
      >
        <DialogTitle>{`${open.action === 'revoke' ? 'Revogar a' : open.action ===  'approve' ? 'Aprovação da ' : 'Reprovação da'} influencer ${contract?.partner_name}?`}</DialogTitle>
        <DialogContent>
          {open.action === 'approve' &&
            <DialogContentText>
              Ao aprovar alguma influencer, as demais serão reprovadas automaticamente.
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApprovePartner} color="primary">
            Sim
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
