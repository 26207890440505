import React, { useEffect, type Node, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Typography, Grid, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { User } from '../../models';
import { addUser, fetchBrandList } from '../../store/actions';
import { renderTextField, renderSelectField } from '../../components/wrapper';
import CustomizedHook from '../../components/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      width: '70%',
      margin: 'auto',
      maxWidth: '100rem'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: '100%',
    minWidth: '200px'
  },
  header: {
    height: '64px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: '24px',
    borderBottom: '1px solid #DFE3E8',
    paddingRight: '24px',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: theme.spacing(1),
    height: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%'
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

type WrapperProps = {
  children: Node
};
const Wrapper = ({ children }: WrapperProps) => (
  <Grid item lg={6} xs={12}>
    {children}
  </Grid>
);

const validate = values => {
  const errors = {};
  ['email', 'username', 'brandId', 'confirmPassword', 'password', 'userType'].forEach(field => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Endereço de email invalido';
  }
  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Nova senha não confere';
  }
  return errors;
};
type Props = {
  brandList: Array<Object>,
  handleSubmit: Function,
  submitting: boolean,
  initialize: Function,
  dispatch: Function,
  onFetchBrandList: Function,
  onAddUser: Function
};
const NewUser = (props: Props) => {
  const classes = useStyles();

  const [brandIds, setBrandIds] = useState([])

  const {
    brandList,
    handleSubmit,
    submitting,
    initialize,
    dispatch,
    onFetchBrandList,
    onAddUser
  } = props;

  useEffect(() => {
    initialize({ type: 'ROLE_MERCHANT' });
    onFetchBrandList();
    // eslint-disable-next-line
  }, [onFetchBrandList]);

  const onSubmit = (user: User) => onAddUser({
    ...user,
    brandIds
  });

  const handleOnBack = () => dispatch(push('/users/list'));

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Novo usuario
        </Typography>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
        <Grid container spacing={1}>
          <Wrapper>
            <Field
              name="email"
              type="text"
              component={renderTextField}
              label="Email"
              className={classes.textField}
            />
          </Wrapper>

          <Wrapper>
            <Field
              name="password"
              type="password"
              component={renderTextField}
              label="Senha"
              className={classes.textField}
            />
          </Wrapper>

          <Wrapper>
            <Field
              name="confirmPassword"
              type="password"
              component={renderTextField}
              label="Confirmar senha"
              className={classes.textField}
            />
          </Wrapper>

          <Wrapper>
            <Field
              name="type"
              component={renderSelectField}
              label="Perfil de usuario"
              className={classes.textField}
            >
              <MenuItem value="ROLE_MERCHANT">Cliente</MenuItem>
              <MenuItem value="ROLE_ADMIN">Administrador</MenuItem>
            </Field>
          </Wrapper>

          <CustomizedHook setValues={setBrandIds} items={brandList} />
        </Grid>

        <div className={classes.footer}>
          <Button
            color="primary"
            disabled={submitting}
            variant="text"
            onClick={handleOnBack}
            className={classes.button}
          >
            Voltar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={submitting}
            variant="contained"
            className={classes.button}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const mapStateToProps = state => {
  const { brandList } = state.filter;
  return { brandList };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBrandList: () => dispatch(fetchBrandList()),
    onAddUser: (user: User) => dispatch(addUser(user))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'newUser',
    validate
  })
)(NewUser);
