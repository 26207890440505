// @flow
export const FETCH_BRAND_LIST = '[BRAND] Fetch brand list';
export const FETCH_BRAND_LIST_SUCCESS = '[BRAND] Fetch brand list Success';
export const FETCH_BRAND_LIST_FAIL = '[BRAND] Fetch brand list Fail';

export const FETCH_PRODUCT_LIST = '[PRODUCT] Fetch product list';
export const FETCH_PRODUCT_LIST_SUCCESS = '[PRODUCT] Fetch product list Success';
export const FETCH_PRODUCT_LIST_FAIL = '[PRODUCT] Fetch product list Fail';

export const FETCH_STATE_LIST = '[STATE] Fetch state list';
export const FETCH_STATE_LIST_SUCCESS = '[STATE] Fetch state list Success';
export const FETCH_STATE_LIST_FAIL = '[STATE] Fetch state list Fail';

export const SELECT_BRAND = '[STATE] Select Brand';

export const SAVE_INITIAL_EDITION = '[STATE] Save initial edition';
export const SAVE_FINAL_EDITION = '[STATE] Save final edition';
