import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5)
  },
  grid: {
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      margin: 'auto',
      maxWidth: '45rem',
      justifyContent: 'center'
    }
  },
  title: {
    fontSize: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(20)
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}));

const NotFound = ({ dispatch }) => {
  const classes = useStyles();

  const handleOnClick = () => dispatch(push('/'));

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid} spacing={1}>
        <Grid item lg={6} xs={12}>
          <Typography variant="h1" className={classes.title}>
            404
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div className={classes.container}>
            <Typography variant="h5 ">PÁGINA NÃO ENCONTRADA!</Typography>
            <Button className={classes.button} onClick={handleOnClick} color="primary">
              <ArrowBack />
              Voltar para a página principal.
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect()(NotFound);
