import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Drawer from '../../../components/Drawer';
import AccessDenied from '../../../components/AccessDenied';
import { isAuthenticated, isAdmin } from '../../../store/services/auth';

const ProtectedRoute = ({ admin, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const { token } = props.match.params;

        if (token) {
          localStorage.setItem('token', token);
        }

        if (!isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: props.location }
              }}
            />
          );
        }
        return admin && !isAdmin() ? (
          <Drawer {...props}>
            <AccessDenied {...props} />
          </Drawer>
        ) : (
          <Drawer {...props}>
            <Component {...props} />
          </Drawer>
        );
      }}
    />
  );
};

export default ProtectedRoute;
