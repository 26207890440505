import React, { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ChartList from './sections/Chart';
import { fetchChartList } from '../../store/actions';
import { white, gray01, darkgray02 } from '../../assets/variables';
import SelectEdition from '../../components/SelectEdition/SelectEdition';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    width: '60%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  subtitle: {
    marginTop: '2rem',
    fontWeight: 'bold',
    color: darkgray02
  },
  datePicker: {
    margin: '0 0 0 0.6rem',
    '&:first-child': {
      margin: 0
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2rem',
    '& p': {
      marginTop: 0
    }
  },
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
    background: gray01,
    border: '1px solid #E7E6E7',
    borderRadius: '4px',
    color: darkgray02,
    '& span': {
      padding: '0 0.7rem'
    },
    '& button': {
      background: white,
      border: 0,
      padding: '0.7rem',
      cursor: 'pointer',
      '&:hover': {
        background: gray01
      }
    }
  },
  borderButton: {
    borderLeft: '1px solid #E7E6E7 !important',
    borderRight: '1px solid #E7E6E7 !important'
  },
  icon: {
    fontSize: '1.4rem',
    paddingLeft: '0.7rem'
  }
}));

const Dashboard = props => {
  const classes = useStyles();

  const { brandSelected, onFetchChartList } = props;
  const { startEdition, endEdition } = useSelector(state => state.filter);

  const updateInfo = useCallback(() => {
    if (brandSelected.id) {
      onFetchChartList(brandSelected.id, null, startEdition, endEdition);
    }
  }, [onFetchChartList, brandSelected, startEdition, endEdition]);

  useEffect(() => {
    if (brandSelected.id) {
      updateInfo();
    }
  }, [brandSelected, updateInfo]);

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <Typography variant="body1" className={classes.title}>
          Dados Gerais
        </Typography>
        <SelectEdition />
      </div>

      <Typography variant="body1" className={classes.subtitle}>
        Sobre os produtos da marca 
{' '}
{brandSelected.name}
      </Typography>
      <ChartList dashboard />
    </div>
  );
};

const mapStateToProps = state => {
  const { brandSelected } = state.filter;
  return { brandSelected };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchChartList: (brandId, productSku, startEdition, endEdition) =>
      dispatch(fetchChartList(brandId, productSku, startEdition, endEdition))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard);
