import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { isEmpty } from 'lodash';
import * as fromTypes from '../types/chart';
import * as fromActions from '../actions';
import { getHeaders } from '../services/auth';

const baseUrl = `${process.env.REACT_APP_BASE_API}/feedback`;

export function* fetchChartList({ brandId, productSku, startEdition, endEdition }) {
  try {
    const response = yield axios.get(
      `${baseUrl}/summarized-feedbacks/dashboards/brand-and-product`,
      {
        headers: getHeaders(),
        params: { brandId, productSku, startEdition, endEdition }
      }
    );
    if (!isEmpty(response) && response.status === 200) {
      yield put(fromActions.fetchChartListSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchChartListFail(error));
  }
}

export function* fetchChartBrand({ brandId, startEdition, endEdition }) {
  try {
    const response = yield axios.get(
      `${baseUrl}/summarized-feedbacks/dashboards/demographic-data`,
      {
        headers: getHeaders(),
        params: { brandId, startEdition, endEdition }
      }
    );
    if (!isEmpty(response) && response.status === 200) {
      yield put(fromActions.fetchChartBrandSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchChartBrandFail(error));
  }
}

export function* fetchChartProduct({ brandId, productId, startEdition, endEdition }) {
  try {
    const response = yield axios.get(
      `${baseUrl}/summarized-feedbacks/dashboards/demographic-data-product`,
      {
        headers: getHeaders(),
        params: { brandId, sku: productId, startEdition, endEdition }
      }
    );

    if (!isEmpty(response) && response.status === 200) {
      yield put(fromActions.fetchChartProductSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchChartProductFail(error));
  }
}

export function* fetchChartByState({ brandId, startEdition, endEdition }) {
  try {
    const response = yield axios.get(
      `${baseUrl}/summarized-feedbacks/dashboards/state-and-age-group`,
      {
        headers: getHeaders(),
        params: { brandId, startEdition, endEdition }
      }
    );
    if (!isEmpty(response) && response.status === 200) {
      yield put(fromActions.fetchChartByStateSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchChartByStateFail(error));
  }
}

export function* watchChart() {
  yield takeLatest(fromTypes.FETCH_CHART_LIST, fetchChartList);
  yield takeLatest(fromTypes.FETCH_CHART_BRAND, fetchChartBrand);
  yield takeLatest(fromTypes.FETCH_CHART_BY_STATE, fetchChartByState);
  yield takeLatest(fromTypes.FETCH_CHART_PRODUCT, fetchChartProduct);
}
