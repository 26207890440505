import * as fromTypes from '../types/edition';

export const editionsRequest = () => ({
  type: fromTypes.EDITIONS_REQUEST,
});

export const editionsRequestSuccess = (editions) => ({
  type: fromTypes.EDITIONS_REQUEST_SUCCESS,
  editions
})
