export const FETCH_CHART_LIST = '[CHART] Fetch Chart Brand General';
export const FETCH_CHART_LIST_SUCCESS = '[CHART] Fetch Chart Brand General Success';
export const FETCH_CHART_LIST_FAIL = '[CHART] Fetch Chart Brand General Fail';

export const FETCH_CHART_BRAND = '[CHART] Fetch Chart Brand Info';
export const FETCH_CHART_BRAND_SUCCESS = '[CHART] Fetch Chart Brand Info Success';
export const FETCH_CHART_BRAND_FAIL = '[CHART] Fetch Chart Brand Info Fail';

export const FETCH_CHART_PRODUCT = '[CHART] Fetch Chart Product Info';
export const FETCH_CHART_PRODUCT_SUCCESS = '[CHART] Fetch Chart Product Info Success';
export const FETCH_CHART_PRODUCT_FAIL = '[CHART] Fetch Chart Product Info Fail';

export const FETCH_CHART_DETAIL = '[CHART] Fetch Chart Detail';

export const FETCH_CHART_BY_STATE = '[CHART] Fetch Chart by State';
export const FETCH_CHART_BY_STATE_SUCCESS = '[CHART] Fetch Chart by State Success';
export const FETCH_CHART_BY_STATE_FAIL = '[CHART] Fetch Chart by State Fail';
