import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import { FaUserCircle } from 'react-icons/fa';
import { Checkbox } from '@material-ui/core';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '1rem 0',
    height: '100%',
    padding: '0.5rem'
  },
  details: {
    display: 'flex',
    alignItems: 'center'
  },
  up: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  content: {},
  cover: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    objectFit: 'cover',
    margin: '0 0.5rem'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  checkbox: ({ clicked }) => ({
    width: 15,
    height: 15,
    borderRadius: '25px',
    border: '1px solid #ccc',
    background: clicked ? '#2b2ed3' : 'transparent'
  }),
  link: {
    color: '#2b2ed3',
    borderBottom: '1px solid #2b2ed3',
    cursor: 'pointer'
  },
  profileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  }
}));

const S3_URL = 'https://s3.amazonaws.com/static.uaubox.com.br/UAUTeam/users';

export default function PartnerCard({ hit }) {
  const [clicked, setClicked] = useState(false);
  const [checked, setChecked] = useState(false);

  const classes = useStyles({ clicked });

  function handleCheck() {
    const ids = localStorage.getItem('b2b:ids')

    if (ids) {
      const parsedIds = JSON.parse(ids)

      if (parsedIds.includes(hit.id)) {
        const newIds = parsedIds.filter(id => id !== hit.id)

        setChecked(false);

        localStorage.setItem('b2b:ids', JSON.stringify(newIds))
      } else {
        setChecked(true);
        localStorage.setItem('b2b:ids', JSON.stringify([
          ...parsedIds,
          hit.id
        ]))
      }
    } else {
      localStorage.setItem('b2b:ids', JSON.stringify([hit.id]))
      setChecked(true);
    }
  }

  useEffect(() => {
    const ids = localStorage.getItem('b2b:ids')

    if (ids) {
      const parsedIds = JSON.parse(ids);

      setChecked(parsedIds.includes(hit.id))
    }
  }, [hit.id])

  function handleSeeMore() {
    setClicked(!clicked);
  }

  const instaUrl = useMemo(() => {
    if (hit?.instagram?.includes('@')) 
      return `https://instagram.com/${hit.instagram.replace('@', '')}`

    if (hit?.instagram?.includes('instagram.com')) 
      return hit.instagram;

    return `https://instagram.com/${hit.instagram}`
  }, [hit.instagram])

  return (
    <Card className={classes.root}>
      <Box className={classes.up}>
        <Checkbox
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          checked={checked}
          onChange={handleCheck}
        />
        <Box className={classes.profileInfo}>
          <div className={classes.details}>
            {hit.profile_url.length > 1 ? (
              <img
                className={classes.cover}
                src={`${S3_URL}/${hit.profile_url}`}
                alt="Live from space album cover"
              />
            ) : (
              <FaUserCircle className={classes.cover} />
            )}
            <Box className={classes.content}>
              <Typography component="h5" variant="h5">
                <a
                  href={instaUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {hit.instagram}
                </a>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {hit.nome}
                {!!hit.idade && ` - ${hit.idade} anos`}
              </Typography>
            </Box>
          </div>
          <Typography className={classes.link} onClick={handleSeeMore}>
            Ver
            {clicked ? ' menos' : ' mais'}
          </Typography>
        </Box>
      </Box>

      <Box
        style={{
          marginRight: 'auto'
        }}
      >
        <Collapse in={clicked} timeout="auto" unmountOnExit>
          <CardContent>
            {hit.calca.length > 1 && <Typography paragraph>{hit.calca}</Typography>}
            {hit.calcado.length > 1 && <Typography paragraph>{hit.calcado}</Typography>}
            {hit.camiseta.length > 1 && <Typography paragraph>{hit.camiseta}</Typography>}
            <Typography paragraph>
              {hit.bairro} - {hit.cidade} - {hit.estado}
            </Typography>
          </CardContent>
        </Collapse>
      </Box>
    </Card>
  );
}
