// @flow
import * as fromTypes from '../types/edition';

type State = {
  loading: boolean,
  editions: string[]
};

export const initialState = {
  loading: false,
  editions: []
};

const reducer = (state: State = initialState, action: fromTypes.CompanyAction) => {
  switch (action.type) {
    case fromTypes.EDITIONS_REQUEST_SUCCESS:
      return {
        ...state,
        editions: action.editions
      };

    default:
      return state;
  }
};

export default reducer;
