// @flow
import * as fromTypes from '../types/chart';

export const initialState = {
  loading: false,
  loaded: false,
  chartBrand: [],
  chartProduct: [],
  chartMap: [],
  chartList: [],
  tableList: { questions: [], products: [] },
  chartDetail: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromTypes.FETCH_CHART_LIST:
      return {
        ...state,
        chartList: [],
        tableList: { questions: [], products: [] },
        loading: true
      };
    case fromTypes.FETCH_CHART_LIST_SUCCESS:
      return {
        ...state,
        chartList: action.payload.chart,
        tableList: action.payload.table,
        loading: false
      };
    case fromTypes.FETCH_CHART_BRAND:
      return {
        ...state,
        chartBrand: [],
        loading: true
      };
    case fromTypes.FETCH_CHART_BRAND_SUCCESS:
      return {
        ...state,
        chartBrand: action.payload,
        loading: false
      };
    case fromTypes.FETCH_CHART_LIST_FAIL:
    case fromTypes.FETCH_CHART_BRAND_FAIL:
      return {
        ...state,
        chartList: [],
        chartBrand: [],
        tableList: { questions: [], products: [] },
        loading: false
      };
    case fromTypes.FETCH_CHART_PRODUCT:
      return {
        ...state,
        chartProduct: [],
        loading: true
      };
    case fromTypes.FETCH_CHART_PRODUCT_SUCCESS:
      return {
        ...state,
        chartProduct: action.payload,
        loading: false
      };
    case fromTypes.FETCH_CHART_PRODUCT_FAIL:
      return {
        ...state,
        chartProduct: [],
        loading: false
      };
    case fromTypes.FETCH_CHART_DETAIL:
      return {
        ...state,
        chartDetail: action.payload
      };
    case fromTypes.FETCH_CHART_BY_STATE:
      return {
        ...state,
        chartProduct: [],
        loading: true
      };
    case fromTypes.FETCH_CHART_BY_STATE_SUCCESS:
      return {
        ...state,
        chartMap: action.payload,
        loading: false,
        loaded: true
      };
    case fromTypes.FETCH_CHART_BY_STATE_FAIL:
      return {
        ...state,
        chartMap: [],
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};

export default reducer;
