import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: ' 100%',
    background: '#fafafa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const NoData = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Sem Resultados
      </Typography>
    </Paper>
  );
};

export default NoData;
