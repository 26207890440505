import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Chart from '../../../../components/Charts';
import { darkgray02 } from '../../../../assets/variables';
import { CustomizedChart } from '../../../../components/Charts/CustomizedChart';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(10% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  }
}));

const oldQuestions = [
  'Já conhecia?',
  'O que achou?',
  'Recomendaria?'
]

const Wrapper = ({ children, size = 4 }) => (
  <Grid item lg={size} xs={12}>
    {children}
  </Grid>
);

const ChartList = props => {
  const classes = useStyles();
  const { loading, chartList, dashboard } = props;

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress size="5rem" className={classes.loader} />
      ) : (
        <Grid container spacing={1}>
          {chartList?.map(question => (
            dashboard && !oldQuestions.includes(question.question)
              ? <Wrapper size={6}>
                  <CustomizedChart question={question} />
                </Wrapper>
              : oldQuestions.includes(question.question) &&
                <Wrapper key={`wrapper-${question.id}`}>
                  <Chart
                      type="pie"
                      title={question.question}
                      data={question.answers}
                      totalAnswers={question.totalAnswers}
                    />
                </Wrapper>
          ))}
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { loading, chartList } = state.chart;
  return { loading, chartList };
};

export default connect(mapStateToProps)(ChartList);
