// @flow
import React, { Node } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { FormHelperText, TextField } from '@material-ui/core';

import './index.css';

const pattern = 'DD/MM/YYYY';

type FieldProps = {
  input: Object,
  label: string,
  meta: Object,
  meta: Object,
  custom: Object,
  children: Node,
  value: string
};

export const renderTextField = ({
  input,
  value,
  label,
  meta: { touched, error },
  ...custom
}: FieldProps) => (
  <div>
    <TextField
      label={label}
      margin="normal"
      fullWidth
      error={touched && !!error}
      variant="outlined"
      value={value}
      {...input}
      {...custom}
    />
    {touched && !!error ? (
      <FormHelperText error={touched && !!error}>{error}</FormHelperText>
    ) : null}
  </div>
);

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}: FieldProps) => (
  <div>
    <TextField
      select
      fullWidth
      label={label}
      margin="normal"
      error={touched && !!error}
      variant="outlined"
      {...input}
      {...custom}
    >
      {children}
    </TextField>
    {touched && !!error ? (
      <FormHelperText error={touched && !!error}>{error}</FormHelperText>
    ) : null}
  </div>
);

export const renderDatePicker = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}: FieldProps) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <KeyboardDatePicker
      autoOk
      fullWidth
      label={label}
      inputVariant="outlined"
      margin="normal"
      format={pattern}
      error={touched && !!error}
      onChange={input.onChange}
      value={input.value === '' ? null : input.value}
      {...custom}
    />
  </MuiPickersUtilsProvider>
);
