// @flow
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Node } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { renderTextField } from '../../components/wrapper';
import { updatePassword } from '../../store/actions/user';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      width: '70%',
      margin: 'auto',
      maxWidth: '100rem'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: '100%'
  },
  header: {
    height: '64px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: '24px',
    borderBottom: '1px solid #DFE3E8',
    paddingRight: '24px',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: theme.spacing(1),
    height: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%'
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

type WrapperProps = {
  children: Node
};
const Wrapper = ({ children }: WrapperProps) => (
  <Grid item xs={12}>
    {children}
  </Grid>
);

const validate = values => {
  const errors = {};
  ['newPassword', 'confirmPassword'].forEach(field => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
  });
  if (values.newPassword && values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Nova senha não confere';
  }
  return errors;
};

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  dispatch: Function,
  match: Object,
  onUpdatePassword: Function
};
const ChangePassword = (props: Props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, dispatch, match, onUpdatePassword } = props;

  const onSubmit = ({ newPassword }) => onUpdatePassword(match.params.id, newPassword);

  const handleOnCancel = () => dispatch(goBack());

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Perfil de usuario
        </Typography>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
        <Grid container spacing={1}>
          <Wrapper>
            <Field
              name="newPassword"
              type="password"
              component={renderTextField}
              label="Nova senha"
              className={classes.textField}
            />
          </Wrapper>

          <Wrapper>
            <Field
              name="confirmPassword"
              type="password"
              component={renderTextField}
              label="Confirmar senha"
              className={classes.textField}
            />
          </Wrapper>
        </Grid>

        <div className={classes.footer}>
          <Button
            color="primary"
            disabled={submitting}
            variant="text"
            onClick={handleOnCancel}
            className={classes.button}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={submitting}
            variant="contained"
            className={classes.button}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { match: ownProps.match };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdatePassword: (id, password) => dispatch(updatePassword(id, password))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'updatePassword',
    validate
  })
)(ChangePassword);
