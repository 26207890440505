import { all } from 'redux-saga/effects';
import { watchAuth } from './auth';
import { watchFilter } from './filter';
import { watchTable } from './table';
import { watchUser } from './user';
import { watchChart } from './chart';
import { watchCompany } from './company';
import { watchEmailMarketing } from './emailMarketing';
import { watchEdition } from './edition';
import { watchSocialCommerce } from './socialcommerce';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchFilter(),
    watchTable(),
    watchUser(),
    watchChart(),
    watchCompany(),
    watchEmailMarketing(),
    watchEdition(),
    watchSocialCommerce()
  ]);
}
