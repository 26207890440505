export default {
  MOBILES: '320px',
  MOBILEM: '375px',
  MOBILEL: '425px',
  MOBILEML: '525px',
  MOBILEXL: '575px',
  TABLET: '768px',
  TABLETM: '800px',
  LAPTOPS: '900px',
  LAPTOP: '1024px',
  LAPTOPM: '1220px',
  LAPTOPL: '1440px',
  DESKTOP: '1860px',
  DESKTOPL: '2560px'
};
