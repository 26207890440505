import React, { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Typography, CircularProgress } from '@material-ui/core';

import { fetchChartProduct, fetchChartList } from '../../store/actions';
import { white, gray01, darkgray02 } from '../../assets/variables';
import ChartList from './sections/Chart';
import PieChartList from '../Dashboard/sections/Chart/ChartList';
import {convertEditions} from '../../utils/convertEditions';

import './index.css';
import SelectEdition from '../../components/SelectEdition/SelectEdition';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(20% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '60%',
    marginTop: '1rem',
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  subtitle: {
    width: '100%',
    color: darkgray02,
    fontSize: theme.spacing(2.6)
  },
  filterContainer: {
    display: 'flex',
    width: '40%'
  },
  datePicker: {
    margin: '0 0 0 0.6rem',
    '&:first-child': {
      margin: 0
    }
  },
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
    background: gray01,
    border: '1px solid #E7E6E7',
    borderRadius: '4px',
    color: darkgray02,
    '& span': {
      padding: '0 0.7rem'
    },
    '& button': {
      background: white,
      border: 0,
      padding: '0.7rem',
      cursor: 'pointer',
      '&:hover': {
        background: gray01
      }
    }
  },
  borderButton: {
    borderLeft: '1px solid #E7E6E7 !important',
    borderRight: '1px solid #E7E6E7 !important'
  },
  icon: {
    fontSize: '1.4rem',
    paddingLeft: '0.7rem'
  },
  tabs: {
    marginTop: '2rem',
    '& button': {
      textTransform: 'none'
    }
  },
  formControl: {
    margin: '0 0.5rem',
    width: '100%'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span" variant="body1">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Product = props => {
  const { brandSelected, productList, onFetchChartProduct, chartProduct, loading, match } = props;
  const classes = useStyles();

  const [productName, setProductName] = React.useState('');
  const [value, setValue] = React.useState(0);

  const [productEdition, setProductEdition] = React.useState('');

  const { startEdition, endEdition } = useSelector(state => state.filter);

  const updateInfo = useCallback(() => {
    if (brandSelected.id) {
      onFetchChartProduct(brandSelected.id, match.params.id, startEdition, endEdition);
    }
  }, [onFetchChartProduct, brandSelected, startEdition, endEdition, match.params.id]);

  useEffect(() => {
    if (brandSelected.id && productList.length > 0) {
      for (let i = 0; i < productList.length; i += 1) {
        if (productList[i].sku === match.params.id) {
          setProductName(productList[i].name);
              setProductEdition(convertEditions([productList[i].edition])[0]);
              break;
        }
      }
      updateInfo();
    }
  }, [brandSelected, match.params.id, productList, setProductName, setProductEdition, updateInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calcTotalVotes = ({ data }) => {
    return data?.reduce((accumulator, currentValue) => accumulator + currentValue.numVotes, 0);
  };

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <div className={classes.titleContainer}>
          <Typography variant="body1" className={classes.title}>
            Produto
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {productName}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            Edição de saída: {productEdition.title}
            </Typography>
        </div>
        <SelectEdition />
      </div>
      {loading ? (
        <CircularProgress size="5rem" className={classes.loader} />
      ) : (
        <div>
          <div className={classes.titleContainer}>
            <Typography variant="body1" className={classes.subtitle}>
              Sobre o Produto
            </Typography>
          </div>
          <PieChartList />

          <Tabs
            className={classes.tabs}
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="Questionarios Marca"
          >
            {chartProduct.map((item, index) => (
              <Tab label={item.title} {...a11yProps(index)} key={`tab-${item.id}`} />
            ))}
          </Tabs>

          {chartProduct.map((item, index) => (
            <TabPanel value={value} index={index} key={`tabPanel-${item.id + 1}`}>
              <ChartList
                chart={chartProduct[index]}
                totalVotes={calcTotalVotes(chartProduct[index])}
              />
            </TabPanel>
          ))}
        </div>

      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { brandSelected, productList } = state.filter;
  const { loading, chartProduct } = state.chart;
  return { brandSelected, productList, chartProduct, loading, match: ownProps.match };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchChartProduct: (brandId, productId, startDate, endDate) => {
      dispatch(fetchChartProduct(brandId, productId, startDate, endDate));
      dispatch(fetchChartList(brandId, productId, startDate, endDate));
    }
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(Product);
