import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { isEmpty } from 'lodash';
import * as fromTypes from '../types/company';
import * as fromActions from '../actions/company';
import { getHeaders } from '../services/auth';

const baseUrl = `${process.env.REACT_APP_BASE_API}/feedback`;

export function* fetchCompanyList() {
  try {
    const response = yield axios.get(`${baseUrl}/company/list`, {
      headers: getHeaders()
    });

    if (!isEmpty(response.data) && response.status === 200) {
      yield put(fromActions.fetchCompanyListSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchCompanyListFail(error));
  }
}

export function* watchCompany() {
  yield takeLatest(fromTypes.FETCH_COMPANY_LIST, fetchCompanyList);
}
