import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import moment from 'moment';

import { inventoryApi } from '../../services/api';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    '& div': {
      maxWidth: 900,
    }
  },
  formControl: {
    marginTop: '8px',
    width: '100%'
  },
  image: {
    width: 35,
    height: 35
  },
  paper: {
    display: 'flex',
    padding: '1rem',
    marginTop: '1rem',
    alignItems: 'center',
    '& p': {
      width: '20%'
    },
    '& a': {
      width: '20%'
    }
  },
  waitingShipping: {
    display: 'flex',
    padding: '1rem',
    marginTop: '0.2rem',
    marginBottom: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      width: '20%'
    },
    '& a': {
      width: '20%'
    },
    color: '#fff',
    background: '#ffcc00'
  },
  waitingPayment: {
    display: 'flex',
    padding: '1rem',
    marginTop: '0.2rem',
    marginBottom: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      width: '20%'
    },
    '& a': {
      width: '20%'
    },
    color: '#fff',
    background: '#FF8800'
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 500
  },
  button: {
    margin: '0 0.5rem'
  }
}));

const parseStatus = status => {
  const statuses = {
    NEW: 'NOVO',
    PENDING: 'PENDENTE',
    APPROVED: 'APROVADO',
    BROADCASTING: 'DIVULGANDO',
    RUNNING: 'RODANDO',
    WAITING_PAYMENT: 'AGUARDANDO PAGAMENTO',
    FINISHED: 'FINALIZADO',
    PRODUCT_IN_TRANSIT: 'PRODUTO EM TRANSITO',
    PRODUCT_RECEIVED: 'PRODUTO RECEBIDO',
    CAMPAIGN_FINISHED: 'CAMPANHA FINALIZADA',
    DEFAULT: status
  };

  return statuses[status] ?? statuses.DEFAULT;
};

export default function StepOne({ setCampaignObj, campaignObj, file, setFile }) {
  const classes = useStyles();

  function shouldBeDisabled() {
    return !['NEW', 'APPROVED', 'PENDING'].includes(campaignObj?.status) && campaignObj?.status;
  }

  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  async function loadCategories() {
    const { data: categoriesRes } = await inventoryApi.get('categories/all');
    setCategories(categoriesRes);
  }

  const loadProductTypes = useCallback(async () => {
    const category = categories.find(category => category.name === campaignObj?.segment);

    if (!category) return;

    const { data: productTypesRes } = await inventoryApi.get(
      `categories/${category.id}/product-types/all`
    );

    setProductTypes(productTypesRes || []);
  }, [campaignObj.segment, categories]);

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    if (!campaignObj?.segment) return;
    loadProductTypes();
  }, [campaignObj, loadProductTypes]);

  return (
    <form>
      {/* {campaignObj?.status === 'PENDING' && (
        <Paper className={classes.waitingShipping}>
          <Typography style={{ width: '50%' }}>Realize o envio do produto</Typography>
          <Box>
            <Button
              className={classes.button}
              variant="outlined"
              color="inherit"
            >
              EXIBIR ENDEREÇO
            </Button>
          </Box>
        </Paper>
      )} */}
      {campaignObj?.contracts && campaignObj?.status === 'WAITING_PAYMENT' && (
        <Paper className={classes.waitingPayment}>
          <Typography style={{ width: '50%' }}>Aguardando Pagamento</Typography>
          <Box>
            <Button
              className={classes.button}
              onClick={() => window.open(campaignObj?.payment?.url)}
              variant="outlined"
              color="inherit"
              disabled={!campaignObj?.payment?.url}
            >
              {campaignObj?.payment?.url ? 'PAGAR BOLETO' : 'CONTATE SUPORTE'}
            </Button>
          </Box>
        </Paper>
      )}
      <Box display="flex">
        <TextField
          autoFocus
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          label="Título"
          margin="dense"
          placeholder="Título"
          type="title"
          fullWidth
          required
          disabled={shouldBeDisabled()}
          value={campaignObj.title}
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              title: e.target.value
            })
          }
        />
      </Box>

      <Box display="flex">
        <TextField
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          label="Descrição"
          margin="dense"
          placeholder="Descreva sua campanha"
          type="description"
          fullWidth
          disabled={shouldBeDisabled()}
          required
          value={campaignObj.description}
          multiline
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              description: e.target.value
            })
          }
        />
      </Box>

      <Box display="flex" alignItems="center">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ maxWidth: '100%' }}
        >
          <InputLabel id="Segmento">Segmento</InputLabel>
          <Select
            required
            InputLabelProps={{ shrink: !!campaignObj?.id }}
            labelId="Segmento"
            disabled={shouldBeDisabled()}
            placeholder="Segmento"
            margin="dense"
            value={campaignObj.segment || 'Outro'}
            onChange={e =>
              setCampaignObj({
                ...campaignObj,
                segment: e.target.value
              })
            }
            label="Segmento"
          >
            {!!categories.length &&
              categories?.map(category => (
                <MenuItem key={category.name} value={category.name}>{category.name}</MenuItem>
              ))}
            <MenuItem value="Outro">Outro</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box display="flex">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ maxWidth: '50%' }}
        >
          <InputLabel id="Segmento">Tipo de Produto</InputLabel>
          <Select
            required
            InputLabelProps={{ shrink: !!campaignObj?.id }}
            labelId="Tipo de Produto"
            disabled={shouldBeDisabled()}
            placeholder="Tipo de Produto"
            value={campaignObj.product_type || 'Outro'}
            onChange={e =>
              setCampaignObj({
                ...campaignObj,
                product_type: e.target.value
              })
            }
            label="Tipo de Produto"
          >
            {productTypes.map(productType => (
              <MenuItem value={productType.name}>{productType.name}</MenuItem>
            ))}
            <MenuItem value="Outro">Outro</MenuItem>
          </Select>
        </FormControl>
        <TextField
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          label="Preço do Produto (R$)"
          margin="dense"
          required
          placeholder="Preço do Produto"
          fullWidth
          type="number"
          disabled={shouldBeDisabled()}
          value={campaignObj.product_price}
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              product_price: e.target.value
            })
          }
        />
        <TextField
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          label="Produto"
          margin="dense"
          required
          placeholder="Produto"
          fullWidth
          disabled={shouldBeDisabled()}
          value={campaignObj.product}
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              product: e.target.value
            })
          }
        />
      </Box>

      <Box display="flex">
        <TextField
          style={{ display: campaignObj?.id ? 'block' : 'none' }}
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          margin="dense"
          label="Status"
          placeholder="Status"
          fullWidth
          disabled
          value={parseStatus(campaignObj.status)}
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              status: e.target.value
            })
          }
        />
      </Box>

      {/* <Box display="flex">
        <TextField
          style={{ display: campaignObj?.id ? 'block' : 'none' }}
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          margin="dense"
          label="Código do rastreio"
          placeholder="Tracking URL"
          fullWidth
          value={campaignObj.tracking_url}
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              tracking_url: e.target.value
            })
          }
        />
      </Box> */}

      <Box display="flex">
        <TextField
          autoFocus
          InputLabelProps={{ shrink: campaignObj?.id ? true : undefined }}
          variant="outlined"
          label="Limite de contratos"
          margin="dense"
          placeholder="Limite de contratos"
          type="number"
          fullWidth
          disabled={shouldBeDisabled()}
          required
          value={campaignObj.max_contracts}
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              max_contracts: e.target.value
            })
          }
        />
      </Box>

      <Box display="flex">
        <TextField
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true
          }}
          required
          label="Data de início"
          placeholder="Data de início"
          fullWidth
          disabled={shouldBeDisabled()}
          type="date"
          value={
            campaignObj.start_date && moment.utc(campaignObj.start_date).format('YYYY-MM-DD')
          }
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              start_date: e.target.value
            })
          }
        />
        <TextField
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true
          }}
          required
          label="Data de término"
          disabled={shouldBeDisabled()}
          placeholder="Data de término"
          fullWidth
          type="date"
          value={
            campaignObj.end_date && moment.utc(campaignObj.end_date).format('YYYY-MM-DD')
          }
          onChange={e =>
            setCampaignObj({
              ...campaignObj,
              end_date: e.target.value
            })
          }
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          marginTop: '1rem'
        }}
      >
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            component="label"
            color="primary"
            style={{
              marginRight: '1rem'
            }}
            disabled={shouldBeDisabled()}
          >
            Anexar Briefing
            <input onChange={e => setFile(e.target.files[0])} type="file" hidden />
          </Button>
          {campaignObj?.id && !file?.name ? (
            <Typography>{campaignObj?.briefing.substring(21)}</Typography>
          ) : (
            <Typography>{file?.name}</Typography>
          )}
        </Box>
      </Box>

    </form>
  );
}
