import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FaLock } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import { signIn } from '../../store/actions/auth';

import * as S from './styles';

import GlobalStyle from '../../styles/global';

import rubrica from '../../assets/uaubox-rubrica.png';
import face from '../../assets/face-right.svg';

const SignIn = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = e => {
    e.preventDefault();

    setIsLoading(true);

    props.onSignIn(email, password);

    setIsLoading(false);
  };

  return (
    <>
      <GlobalStyle />
      <S.Container>
        <img src={rubrica} alt="Imagem da rubrica da empresa UAUBox" />
        <S.Form>
          <h1>Login</h1>
          <img src={face} alt="Imagem de uma mascara de uma mulher" />
          <form onSubmit={handleOnSubmit}>
            <label>
              <MdEmail size={20} color="#303030" />
              <input
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder="E-mail"
                required
              />
            </label>
            <label>
              <FaLock size={20} color="#303030" />
              <input
                onChange={e => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Senha"
                required
              />
            </label>

            <p>
              Esqueceu sua senha? 
{' '}
<Link to="/dashboard">Clique aqui</Link>
            </p>

            <button>{isLoading ? 'Aguarde...' : 'Entrar'}</button>
          </form>
          <img src={face} alt="Imagem de uma mascara de uma mulher" />
        </S.Form>
        <S.Welcome>
          <aside>
            <img src={face} alt="Imagem de uma mascara de uma mulher" />
            <h1>Bem vindo de volta</h1>
            <p>
              Sempre bom ter você de volta! Faça seu login ao lado e tenha acesso exclusivo a
              dashboards e os melhores insights para sua marca.
            </p>
          </aside>
        </S.Welcome>
      </S.Container>
    </>
  );
};

const mapStateToProps = state => {
  const { loading } = state.auth;
  return { loading };
};

const mapDispatchToProps = dispatch => {
  return {
    onSignIn: (email, password) => dispatch(signIn(email, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
