import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import fileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import * as fromTypes from '../types/table';
import * as fromActions from '../actions/table';
import { getHeaders, TEXT_CSV } from '../services/auth';
import { Filter } from '../../models';

const baseUrl = `${process.env.REACT_APP_BASE_API}/feedback`;

const parseErrorMsg = error => {
  return error.response.data.message
    ? error.response.data.message
    : 'Não foi possivel se conectar ao servidor';
};
const getFilter = state => state.form.filter;

export function* fetchTableData({ sort, order, page, size }) {
  try {
    const filter = yield select(getFilter);
    const filterValues = new Filter(filter.values);
    const params = {
      ...filterValues,
      sort: sort ? `${sort},${order}` : null,
      page,
      size
    };
    const response = yield axios.get(`${baseUrl}/table/summarized-feedback`, {
      headers: getHeaders(),
      params
    });
    if (!isEmpty(response.data) && response.status === 200) {
      yield put(fromActions.fetchTableSuccess(response.data));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.fetchTableFail(error));
  }
}

export function* exportCsv() {
  try {
    const filter = yield select(getFilter);
    const { brandId } = filter.values;
    const response = yield axios.get(
      `${baseUrl}/table/summarized-feedback/export?brandId=${brandId}`,
      {
        headers: getHeaders(TEXT_CSV)
      }
    );
    if (!isEmpty(response.data) && response.status === 200) {
      fileSaver.saveAs(new Blob([response.data]), 'test.csv');
      yield put(fromActions.exportCsvSuccess());
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.exportCsvFail(error));
  }
}

export function* watchTable() {
  yield takeLatest(fromTypes.FETCH_TABLE, fetchTableData);
  yield takeLatest(fromTypes.EXPORT_CSV, exportCsv);
}
