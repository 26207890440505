import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import footerLogo from '../../assets/img/uau_brand.svg';
import facebook from '../../assets/img/lp-footer-fb.png';
import instagram from '../../assets/img/lp-footer-ig.png';
import { white, footerDefault, footerGray } from '../../assets/variables';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: footerDefault,
    padding: '2rem 0',
    width: '100%',
    fontFamily: 'AvantGarde'
  },
  bgGray: {
    background: footerGray
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1185px',
    width: '100%',
    color: white
  },
  logo: {
    width: '5rem'
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: white,
      fontSize: '0.85rem',
      margin: '0 0.5rem',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  followContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    minWidth: '250px'
  },
  titleFollow: {
    display: 'flex',
    flexWrap: 'nonwrap',
    marginBottom: '0.5rem',
    '& span': {
      fontSize: '1rem',
      width: '7rem'
    },
    '& hr': {
      width: '100%',
      height: 0,
      border: `1px solid ${white}`
    }
  },
  socialContainer: {
    display: 'flex',
    '& a img': {
      height: '1.7rem'
    },
    '& a:last-child': {
      marginLeft: '1rem'
    }
  }
}));

const Footer = ({ isDashboard }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${isDashboard ? classes.bgGray : ''}`}>
      <div className={classes.footer}>
        <img src={footerLogo} alt="" className={classes.logo} />
        <div className={classes.linksContainer}>
          <a href="https://uaubox.com.br/#/terms">TERMOS DE USO</a>
          <a href="https://uaubox.com.br/#/privacy">POLÍTICAS DE PRIVACIDADE</a>
          <a href="https://uaubox.com.br/#/faq">DÚVIDAS</a>
        </div>
        <div className={classes.followContainer}>
          <div className={classes.titleFollow}>
            <span>SIGA-NOS</span>
            <hr className="full-width" />
          </div>
          <div className={classes.socialContainer}>
            <a href="https://www.facebook.com/uaubox" rel="noopener noreferrer" target="_blank">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/uauboxbr/" rel="noopener noreferrer" target="_blank">
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Footer);
