import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  bodyPage: {
    display: 'flex'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
  },
  container: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}));

const MiniDrawer = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <div className={classes.bodyPage}>
        <Menu />
        <main className={classes.content}>
          <div className={classes.container}>{children}</div>
        </main>
      </div>
      <Footer isDashboard />
    </div>
  );
};

export default MiniDrawer;
