import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Typography} from "@material-ui/core";

export default function ShowAddress({
                                      open,
                                      setOpen,
                                      partner
                                    }) {

  const handleClose = () => {
    setOpen(false);
  };

  return !open ? <></> : (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Dados para envio:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>IM: 33967-2</Typography>
            <Typography>IE: 407.694.864.115</Typography>
            <Typography>CNPJ:	28.917.082/0001-52</Typography>
            <Typography>Nome Empresarial:	UAUBOX LTDA</Typography>
            <Typography>Nome Fantasia:	UAUBOX</Typography>
            <Typography>Natureza Jurídica: Sociedade Empresária Limitada</Typography>
            <Typography></Typography>
            <Typography>Endereço</Typography>
            <Typography>Logradouro: Av. Antonieta Piva Barranqueiros, 62 - Retiro, Jundiaí - SP, 13213-009, </Typography>
            <Typography>CLA Jundiaí, Galpão 23.</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
