// @flow
import * as fromTypes from '../types/chart';

export const fetchChartList = (brandId, productSku, startEdition, endEdition) => ({
  type: fromTypes.FETCH_CHART_LIST,
  brandId,
  productSku,
  startEdition,
  endEdition
});

export const fetchChartListSuccess = payload => ({
  type: fromTypes.FETCH_CHART_LIST_SUCCESS,
  payload
});

export const fetchChartListFail = payload => ({
  type: fromTypes.FETCH_CHART_LIST_FAIL,
  payload
});

export const fetchChartBrand = (brandId, startEdition, endEdition) => ({
  type: fromTypes.FETCH_CHART_BRAND,
  brandId,
  startEdition,
  endEdition,
});

export const fetchChartBrandSuccess = payload => ({
  type: fromTypes.FETCH_CHART_BRAND_SUCCESS,
  payload
});

export const fetchChartBrandFail = payload => ({
  type: fromTypes.FETCH_CHART_BRAND_FAIL,
  payload
});

export const fetchChartProduct = (brandId, productId, startEdition, endEdition) => ({
  type: fromTypes.FETCH_CHART_PRODUCT,
  brandId,
  productId,
  startEdition,
  endEdition
});

export const fetchChartProductSuccess = payload => ({
  type: fromTypes.FETCH_CHART_PRODUCT_SUCCESS,
  payload
});

export const fetchChartProductFail = payload => ({
  type: fromTypes.FETCH_CHART_PRODUCT_FAIL,
  payload
});

export const fetchChartDetail = payload => ({
  type: fromTypes.FETCH_CHART_DETAIL,
  payload
});

export const fetchChartByState = (brandId, startEdition, endEdition) => ({
  type: fromTypes.FETCH_CHART_BY_STATE,
  brandId,
  startEdition,
  endEdition
});

export const fetchChartByStateSuccess = payload => ({
  type: fromTypes.FETCH_CHART_BY_STATE_SUCCESS,
  payload
});

export const fetchChartByStateFail = payload => ({
  type: fromTypes.FETCH_CHART_BY_STATE_FAIL,
  payload
});
