// @flow
import {
  Button, Paper,
  Table,





  TableBody, TableCell,
  TableHead, TablePagination,


  TableRow,
  TableSortLabel,

  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Loading from '../../components/Loading';
import { fetchBrandList, fetchUserList } from '../../store/actions';

const headRows = [
  { id: 'email', numeric: false, label: 'Email', sortable: true },
  { id: 'brandId', numeric: true, label: 'Marcas', sortable: false },
  { id: 'type', numeric: true, label: 'Perfil de acesso', sortable: false },
  { id: 'updatedAt', numeric: true, label: 'Atualizado em:', sortable: false }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    minheight: '30rem',
    [theme.breakpoints.down('sm')]: {
      height: '22rem'
    },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
      margin: 'auto',
      maxWidth: '100rem'
    }
  },
  toolbar: {
    minHeight: theme.spacing(10),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  tableBox: {
    height: '20rem',
    [theme.breakpoints.down('sm')]: {
      height: '10rem'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(5)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

const EmptyRow = ({ isLoading }) => (
  <TableRow>
    <TableCell colSpan={9} style={{ padding: 0 }}>
      <Loading isLoading={isLoading} />
    </TableCell>
  </TableRow>
);

type Props = {
  userList: Object,
  brandList: Array<Object>,
  loading: boolean,
  dispatch: Function,
  onFetchUserList: Function,
  onFetchBrandList: Function
};
const UserList = (props: Props) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [sort, setSort] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const { userList, loading, dispatch, onFetchUserList, onFetchBrandList, brandList } = props;
  const { totalElements = 0 } = userList;

  useEffect(() => {
    onFetchUserList();
    onFetchBrandList();
  }, [onFetchUserList, onFetchBrandList]);

  const handleRequestSort = property => {
    const isDesc = sort === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    setOrder(newOrder);
    setSort(property);
    onFetchUserList(property, newOrder, page, size);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onFetchUserList(sort, order, newPage, size);
  };

  const handleChangesize = event => {
    const newsize = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setSize(newsize);
    onFetchUserList(sort, order, newPage, newsize);
  };

  const handleNewUser = () => dispatch(push('/users/new'));

  const handleDetail = id => dispatch(push(`/users/details/${id}`));

  const getBrandName = ids => ids ? brandList.filter(brand => ids.includes(brand.id)).map(brand => brand.name).join(', ') : []

  const getSortLabel = row => {
    return row.sortable ? (
      <TableSortLabel
        active={sort === row.id}
        hideSortIcon
        direction={order}
        onClick={() => handleRequestSort(row.id)}
      >
        {row.label}
      </TableSortLabel>
    ) : (
      row.label
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.actions}>
          <Tooltip title="Novo Usuario">
            <Button variant="contained" color="primary" onClick={handleNewUser}>
              Novo Usuario
            </Button>
          </Tooltip>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium">
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align={row.numeric ? 'right' : 'left'}
                    sortDirection={sort === row.id ? order : false}
                  >
                    {getSortLabel(row)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.length > 0 ? (
                userList
                .filter(user => ['ROLE_ADMIN', 'ROLE_MERCHANT'].includes(user.type))
                .map(row => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`index-${row.id}`}
                    onClick={() => handleDetail(row.id)}
                    className={classes.tableRow}
                  >
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="right">{getBrandName(row.data.brandIds)}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{moment(row.updatedAt).format('L')}</TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyRow isLoading={loading} />
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalElements}
          rowsPerPage={size}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangesize}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = state => {
  const { loading, userList } = state.user;
  const { brandList } = state.filter;
  return { loading, userList, brandList };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUserList: (sort, order, page, size) => dispatch(fetchUserList(sort, order, page, size)),
    dispatch,
    onFetchBrandList: () => dispatch(fetchBrandList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);
