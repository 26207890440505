import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch';

import { updateCampaignRequest, createCampaignRequest } from '../../store/actions'
import { getBrandId } from '../../store/services/auth';
import { createInMemoryCache } from '@algolia/cache-in-memory';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, #2421f2 0%, #9cb0f3 50%, #b4ccff 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, #2421f2 0%, #9cb0f3 50%, #b4ccff 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, #2421f2 0%, #9cb0f3 50%, #b4ccff 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
    'linear-gradient( 136deg, #2421f2 0%, #9cb0f3 50%, #b4ccff 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions: {
    margin: '1rem 0',
  }
}));

function getSteps() {
  return ['Configuração da campanha', 'Parceiras', 'Solicitações'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Configuração da campanha';
    case 1:
      return 'Parceiras';
    case 2:
      return 'Solicitações';
    default:
      return 'Desconhecido';
  }
}

const searchClient = algoliasearch('J9REWNDIJ0', 'ad389184f4dc00654e53c4e89f094d5d', {
  // Caches responses from Algolia
  responsesCache: createInMemoryCache(), // or createNullCache()

  // Caches Promises with the same request payload
  requestsCache: createInMemoryCache({ serializable: false }) // or createNullCache()
});

const index = searchClient.initIndex("PROD_UAUTEAM");

export default function Steppers({
  campaignId,
  setCampaignId,
  setOpen,
  editCampaignObj
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [campaignObj, setCampaignObj] = React.useState({});
  const [file, setFile] = React.useState({});

  useEffect(() => {
    if (editCampaignObj?.id) {
      setCampaignObj(editCampaignObj)
    }
  }, [editCampaignObj])

  const brandList = useSelector(state => state.filter.brandList);
  const campaign = useSelector(state => state.socialcommerce.campaign);

  const brandIds = getBrandId();

  const brand = brandList.find(brand => brand.id === brandIds[0]);

  const steps = getSteps();

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };

  async function handleUpdateCampaign() {
    const formData = new FormData();

    if (file) {
      const newFile = new File([file], file.name, { type: file.type });

      formData.append('file', newFile);
    }

    const ids = localStorage.getItem('b2b:ids');
    
    const filter = localStorage.getItem('b2b:filter');

    let allowed_partners = ids ? JSON.parse(ids) : [];
    let restricted = false;

    if (filter && !allowed_partners.length) {
      const {
        filters,
        numericFilters,
        query
      } = JSON.parse(filter);

      const response = await index.search(query, {
        filters,
        numericFilters,
        hitsPerPage: 1000
      });

      allowed_partners = [...response.hits.map(hit => hit.id)];
      restricted = true;
    }

    dispatch(updateCampaignRequest({ campaign: {
      ...campaignObj,
      allowed_partners, 
      restricted,
    }, formData: file ? formData : null }));

    handleClose(false);
  }

  async function handleCreateCampaign() {
    const formData = new FormData();

    if (file) {
      const newFile = new File([file], file.name, { type: file.type });

      formData.append('file', newFile);
    }

    const ids = localStorage.getItem('b2b:ids');
    
    const filter = localStorage.getItem('b2b:filter');

    let allowed_partners = ids ? JSON.parse(ids) : [];
    let restricted = false;

    if (filter && !allowed_partners.length) {
      const {
        filters,
        numericFilters,
        query
      } = JSON.parse(filter);

      const response = await index.search(query, {
        filters,
        numericFilters,
        hitsPerPage: 1000
      });

      allowed_partners = [...response.hits.map(hit => hit.id)];
      restricted = true;
    }

    dispatch(
      createCampaignRequest({
        campaign: {
          ...campaignObj,
          allowed_partners,
          restricted
        },
        formData,
        brand_name: brand.name
      })
    );

    handleClose(false);
  }

  const handleNext = () => {
    if (activeStep === 2 || (activeStep === 1 && !campaign?.id)) {
      campaign?.id 
        ? handleUpdateCampaign() 
        : handleCreateCampaign();

      return;
    }

    const nextStep = shouldBeDisabled() ? 2 : 1;

    setActiveStep((prevActiveStep) => prevActiveStep + nextStep);
  };

  const handleBack = () => {
    const backStep = shouldBeDisabled() ? 2 : 1;

    setActiveStep((prevActiveStep) => prevActiveStep - backStep);
  };

  const handleChangeCampaign = (data) => {
    setCampaignObj(data)
    localStorage.setItem('b2b:campaign', JSON.stringify(data))
  }
  
  const handleChangeFile = (file) => {
    handleChangeCampaign({
      ...campaignObj,
      briefing: file?.name,
    })

    setFile(file)
  }

  const validFields = useMemo(() => 
    !campaignObj?.title || 
    !campaignObj?.description ||
    !campaignObj?.segment ||
    !campaignObj?.product_type ||
    !campaignObj?.product_price ||
    !campaignObj?.product ||
    !campaignObj?.start_date ||
    !campaignObj?.end_date ||
    !campaignObj?.max_contracts ||
    (!file?.name && !campaignObj?.briefing)
  , [campaignObj, file])

  const stepsMapped = useMemo(() => campaign?.id ? steps : steps.filter(step => step !== 'Solicitações'), [campaign.id, steps])

  function shouldBeDisabled() {
    return !['NEW', 'APPROVED', 'PENDING'].includes(campaignObj?.status) && campaignObj?.status;
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {stepsMapped.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          {activeStep === 0 && <StepOne 
            campaignId={campaignId}
            setCampaignId={setCampaignId}
            setOpen={setOpen}
            setCampaignObj={handleChangeCampaign}
            campaignObj={campaignObj}
            file={file}
            setFile={handleChangeFile}
          />}
          {activeStep === 1 && <StepTwo />}
          {activeStep === 2 && campaign?.id && shouldBeDisabled() && <StepThree campaignObj={campaignObj} />}
          <div className={classes.actions}>
            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              disabled={validFields}
            >
              {activeStep === stepsMapped.length - 1 ||
                (activeStep === 1 && !campaign.id) 
                  ? 'Finalizar' 
                  : 'Próxima'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
