// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import BarChart from './BarChart';
import PieChart from './PieChart';
import Loading from '../Loading';

import { gray02, darkgray02 } from '../../assets/variables';

const useStyles = makeStyles(theme => ({
  root: ({ type, answers }) => ({
    background: theme.background ? theme.background : gray02,
    height: type === 'barVertical' ? '30rem' : '24rem',
    padding: theme.spacing(3)
  }),
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    color: darkgray02,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2.5)
    }
  }
}));

const getChartByType = type => {
  if (type === 'bar') {
    return BarChart;
  }
  if (type === 'pie') {
    return PieChart;
  }

  throw new Error('Invalid chart type');
};

const Chart = props => {
  const { type, data, title, totalAnswers, isLoading, classroot } = props;
  const classes = useStyles({ type, answers: data.length });
  const Component = getChartByType(type);

  return (
    <div className={classroot || classes.root}>
      <Loading isLoading={isLoading} hasData={data.length > 0}>
        <div className={classes.paper}>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          <Component data={data} totalAnswers={totalAnswers} />
        </div>
      </Loading>
    </div>
  );
};

export default Chart;
