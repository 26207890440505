// @flow

class TableSort {
  sort: string;

  order: string;

  page: number;

  size: number;

  constructor(data: any = {}) {
    this.sort = data.sort || '';
    this.order = data.order || 'asc';
    this.page = data.page || 0;
    this.size = data.size || 5;
  }
}

export default TableSort;
