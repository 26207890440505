import axios from 'axios';
import { isEmpty } from 'lodash';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { initialize } from 'redux-form';
import { put, takeLatest } from 'redux-saga/effects';
import * as fromActions from '../actions/user';
import { getHeaders } from '../services/auth';
import * as fromTypes from '../types/user';

const baseUrl = `${process.env.REACT_APP_BASE_API}/authentication/admin`;

const parseErrorMsg = error => {
  const message = error.response.data.message || error.response.data.error;
  return message || 'Não foi possivel se conectar ao servidor';
};

export function* userDetails({ id }) {
  try {
    const response = yield axios.get(`${baseUrl}/users/${id}`, {
      headers: getHeaders()
    });
    if (response.status === 200) {
      yield put(fromActions.userDetailsSuccess(response.data));
      yield put(initialize('userDetails', response.data));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.userDetailsFail(error));
  }
}

export function* updatePassword({ id, password }) {
  try {
    const response = yield axios.put(
      `${baseUrl}/users/${id}/change-password`,
      {
        password
      },
      {
        headers: getHeaders()
      }
    );
    if (response.status === 200) {
      toast.success('Senha atualizada com sucesso!');
      yield put(fromActions.updatePasswordSuccess());
      yield put(push(`/users/details/${id}`));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.updatePasswordFail());
  }
}

export function* updateUser({ user }) {
  try {
    const response = yield axios.put(`${baseUrl}/users`, user, {
      headers: getHeaders()
    });
    if (!isEmpty(response.data) && response.status === 200) {
      const { user: updatedUser } = response.data;
      toast.success('Usuário atualizado com sucesso!');
      yield put(fromActions.updateUserSuccess(updatedUser));
      yield put(initialize('userDetails', updatedUser));
      yield put(push('/users/list'));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.updateUserFail());
  }
}

export function* fetchUserList({ sort, order, page, size }) {
  try {
    const params = {
      sort: sort ? `${sort},${order}` : null,
      page,
      size: 500
    };
    const response = yield axios.get(`${baseUrl}/users`, {
      headers: getHeaders(),
      params
    });

    if (!isEmpty(response.data.content) && response.status === 200) {
      yield put(fromActions.fetchUserListSuccess(response.data.content));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.fetchUserListFail(error));
  }
}

export function* addUser({ user }) {
  try {
    const response = yield axios.post(`${baseUrl}/users`, {
      ...user,
      data: { brandIds: user.brandIds }
    }, {
      headers: getHeaders()
    });
    if (response?.status === 201) {
      toast.success('Usuário cadastrado com sucesso!');
      yield put(fromActions.fetchUserList());
      yield put(push('/users/list'));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.addUserFail(error));
  }
}

export function* watchUser() {
  yield takeLatest(fromTypes.USER_DETAILS, userDetails);
  yield takeLatest(fromTypes.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(fromTypes.UPDATE_USER, updateUser);
  yield takeLatest(fromTypes.FETCH_USER_LIST, fetchUserList);
  yield takeLatest(fromTypes.ADD_USER, addUser);
}
