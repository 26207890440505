import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, CircularProgress, Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, NavLink } from 'react-router-dom';
import headerLogo from '../../assets/img/uau_brand.svg';
import { fetchBrandList, fetchProductList, selectBrand, signOut, me } from '../../store/actions';
import { gray01, gray500, darkgray, darkgray01, darkgray02 } from '../../assets/variables';

import { getUser, isAdmin } from '../../store/services/auth';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  menuBar: {
    background: gray01,
    width: '19rem',
    padding: '2rem 0',
    '& section': {
      padding: '1.2rem 0',
      '& a': {
        display: 'block',
        padding: '0.3rem 1rem 0.3rem 3.5rem',
        color: darkgray01,
        textDecoration: 'none',
        '&:hover': {
          background: darkgray,
          color: darkgray02,
          '& p': {
            fontWeight: 'bold'
          }
        },
        '& p': {
          fontSize: '1rem'
        }
      }
    }
  },
  active: {
    textDecoration: 'none',
    background: darkgray,
    color: `${darkgray02} !important`,
    '& p': {
      fontWeight: 'bold'
    }
  },
  logo: {
    margin: '0 auto 2rem',
    padding: '0.8rem',
    display: 'block'
  },
  brandSelected: {
    color: darkgray02,
    fontWeight: '500',
    fontSize: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.8rem 1rem 0.8rem 1.5rem',
    '& div': {
      display: 'flex',
      alignItems: 'center'
    },
    '& img': {
      width: '3rem',
      borderRadius: '5px',
      marginRight: '0.7rem'
    }
  },
  brandActiveMenu: {
    background: gray500
  },
  loader: {
    margin: '0 auto',
    color: darkgray02,
    display: 'block'
  },
  divider: {
    margin: '0 3rem',
    height: '2px',
    background: darkgray
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    paddingLeft: '3.5rem',
    marginBottom: '0.8rem',
    color: darkgray02
  }
}));

const NavMenu = props => {
  const classes = useStyles();

  const {
    loading,
    brandList,
    productList,
    brandSelected,
    onFetchBrandList,
    onFetchProductList,
    onSelectBrand,
    onMe
  } = props;

  useEffect(() => {
    onMe();

    const userStorage = getUser();

    if (!userStorage) return;

    onFetchBrandList();
  }, [onFetchBrandList, onMe]);

  useEffect(() => {
    if (!brandSelected) return;

    onSelectBrand(brandSelected);
  }, [brandSelected, onSelectBrand]);

  const handleSelectBrand = brand => {
    onSelectBrand(brand);
    onFetchProductList(brand.id);
    localStorage.setItem('uauBoxB2BBrandId', brand.id);
  };

  return (
    <div className={classes.root}>
      <div className={classes.menuBar}>
        <img src={headerLogo} alt="Logo Uaubox" className={classes.logo} />
        {loading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <>
            <Button className={classes.brandSelected}>
              <div className="">
                <img
                  src={`https://d2ivdm85owtv7v.cloudfront.net/images/b2b/${brandSelected?.name
                    ?.replace(/ /g, '-')
                    .replace('?', '%3F')
                    .toLowerCase()}.png`}
                  alt="Logo marca"
                  onError={e => {
                    e.target.src = 'https://i.stack.imgur.com/Pwbuz.png';
                  }}
                />
              </div>
              <Autocomplete
                id="disable-clearable"
                disableClearable
                noOptionsText="Não achei nada :("
                onChange={(_, newValue) => {
                  handleSelectBrand(brandList.find(brand => brand.name === newValue?.name));
                }}
                value={brandSelected?.name}
                options={brandList || []}
                getOptionLabel={option => option?.name ?? option}
                style={{ width: '100%' }}
                renderInput={params => <TextField label="Marca" {...params} />}
              />
            </Button>
          </>
        )}

        <section>
          <NavLink to="/dashboard" variant="body2" activeClassName={classes.active}>
            <Typography variant="body2">Dashboard</Typography>
          </NavLink>
          <NavLink to="/campanhas" variant="body2" activeClassName={classes.active}>
            <Typography variant="body2">Campanhas</Typography>
          </NavLink>
          <NavLink to="/divisao-por-estado" variant="body2" activeClassName={classes.active}>
            <Typography variant="body2">Dados demográficos</Typography>
          </NavLink>
          <NavLink to="/dados" variant="body2" activeClassName={classes.active}>
            <Typography variant="body2">Dados</Typography>
          </NavLink>
          {isAdmin() && (
            <NavLink to="/users/list" variant="body2">
              <Typography variant="body2">Usuários</Typography>
            </NavLink>
          )}
          <NavLink onClick={() => props.onSignOut()} to="/" variant="body2">
            <Typography variant="body2">Sair</Typography>
          </NavLink>
        </section>

        <Divider className={classes.divider} />

        <section>
          <Typography variant="body2" className={classes.title}>
            Produtos
          </Typography>

          {productList.map((p, index) => (
            <NavLink
              to={`/produto/${p.sku}`}
              variant="body2"
              key={`link-${p.sku}-${index + 1}`}
              activeClassName={classes.active}
            >
              <Typography variant="body2">{p.name}</Typography>
            </NavLink>
          ))}
        </section>
      </div>
    </div>
  );
};

// export default withRouter(NavMenu);

const mapStateToProps = state => {
  const { loading, brandList, brandSelected, productList, stateList } = state.filter;
  const { tableSort } = state.table;
  return { loading, brandList, brandSelected, productList, stateList, tableSort };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectBrand: brand => dispatch(selectBrand(brand)),
    onFetchBrandList: () => dispatch(fetchBrandList()),
    onFetchProductList: brandId => dispatch(fetchProductList(brandId)),
    onSignOut: () => dispatch(signOut()),
    onMe: () => dispatch(me())
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(NavMenu));
