// @flow
import * as fromTypes from '../types/company';

type State = {
  loading: boolean,
  companyList: []
};

export const initialState = {
  loading: false,
  companyList: []
};

const reducer = (state: State = initialState, action: fromTypes.CompanyAction) => {
  switch (action.type) {
    case fromTypes.FETCH_COMPANY_LIST:
      return {
        ...state,
        loading: true
      };
    case fromTypes.FETCH_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        loading: false
      };
    case fromTypes.FETCH_COMPANY_LIST_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
