// @flow
import * as fromTypes from '../types/user';
import { User } from '../../models';

type State = {
  loading: boolean,
  user: User,
  userList: Object
};

export const initialState = {
  loading: false,
  user: {},
  userList: {}
};

const reducer = (state: State = initialState, action: fromTypes.UserAction) => {
  switch (action.type) {
    case fromTypes.USER_DETAILS:
    case fromTypes.UPDATE_USER:
    case fromTypes.FETCH_USER_LIST:
    case fromTypes.ADD_USER:
      return {
        ...state,
        loading: true
      };
    case fromTypes.USER_DETAILS_SUCCESS:
    case fromTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: new User(action.payload),
        loading: false
      };
    case fromTypes.FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false
      };
    case fromTypes.USER_DETAILS_FAIL:
    case fromTypes.UPDATE_USER_FAIL:
    case fromTypes.FETCH_USER_LIST_FAIL:
    case fromTypes.ADD_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
