import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { inventoryApi } from '../../services/api';
import Steppers from './Steppers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    '& div': {
      maxWidth: 900,
      '& div': {
        marginRight: '1rem'
      }
    }
  },
  formControl: {
    marginTop: '8px',
    width: '100%'
  },
  image: {
    width: 35,
    height: 35
  },
  paper: {
    display: 'flex',
    padding: '1rem',
    marginTop: '1rem',
    alignItems: 'center',
    '& p': {
      width: '20%'
    },
    '& a': {
      width: '20%'
    }
  },
  waitingShipping: {
    display: 'flex',
    padding: '1rem',
    marginTop: '0.2rem',
    marginBottom: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      width: '20%'
    },
    '& a': {
      width: '20%'
    },
    color: '#fff',
    background: '#ffcc00'
  },
  waitingPayment: {
    display: 'flex',
    padding: '1rem',
    marginTop: '0.2rem',
    marginBottom: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      width: '20%'
    },
    '& a': {
      width: '20%'
    },
    color: '#fff',
    background: '#FF8800'
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 500
  },
  button: {
    margin: '0 0.5rem'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ setCampaignId, campaignId, open, setOpen }) {
  const classes = useStyles();

  const [campaignObj, setCampaignObj] = useState({});

  const campaign = useSelector(state => state.socialcommerce.campaign)

  useEffect(() => {
    if (campaign?.id) 
      setCampaignObj(campaign)
  }, [campaign])

  const handleClickOpen = () => {
    setCampaignObj({});
    setCampaignId('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCampaignObj({})
  };

  const [categories, setCategories] = useState([]);

  async function loadCategories() {
    const { data: categoriesRes } = await inventoryApi.get('categories/all');
    setCategories(categoriesRes);         
  }

  const loadProductTypes = useCallback(async () => {
    const category = categories.find(category => category.name === campaignObj?.segment);

    if (!category) return;

    await inventoryApi.get(
      `categories/${category.id}/product-types/all`
    );
  }, [campaignObj.segment, categories]);

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    if (!campaignObj?.segment) return;
    loadProductTypes();   
  }, [campaignObj, loadProductTypes]);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Nova Campanha
      </Button>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-placeholder="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {campaignObj.id ? 'Editar' : 'Nova'} Campanha
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialog}>
          <Steppers 
            campaignId={campaignId}
            open={open}
            setCampaignId={setCampaignId}
            setOpen={setOpen}
            editCampaignObj={campaignObj}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
