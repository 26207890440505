// @flow
import React, { type Node } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import NoData from './NoData';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    background: '#fafafa',
    minHeight: '8rem'
  },
  children: {
    height: '100%',
    width: '100%'
  },
  progress: {
    margin: theme.spacing(2)
  }
}));

type Props = {
  isLoading: boolean,
  hasData: boolean,
  children: Node
};
const Loading = (props: Props) => {
  const { isLoading, children, hasData } = props;
  const classes = useStyles();

  const renderData = value =>
    value ? <div className={classes.children}>{children}</div> : <NoData />;

  return isLoading ? (
    <div className={classes.paper}>
      <CircularProgress className={classes.progress} />
    </div>
  ) : (
    renderData(hasData)
  );
};

export default Loading;
