import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import * as fromTypes from '../types/auth';
import * as fromActions from '../actions/auth';
import * as fromFilterActions from '../actions/filter';
import { setToken, logout, setUser, getToken } from '../services/auth';

import api from '../../services/api';

const parseErrorMsg = error => {
  return error?.response?.data?.message
    ? error.response.data.message
    : 'Não foi possivel se conectar ao servidor';
};

export function* signIn({ email, password }) {
  try {
    const response = yield api.post(`authentication/users/sign-in`, {
      grantType: 'password',
      email,
      password
    });

    if (response.status === 200) {
      const token = response.data.accessToken;

      const userResponse = yield api.get(`authentication/users/me`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });

      yield setToken(token);
      yield setUser(userResponse?.data);
      yield put(fromActions.signInSuccess());

      yield put(push('/dashboard'));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.signInFail(error));
  }
}

export function* me() {
  try {
    const token = getToken();
    const userResponse = yield api.get(`authentication/users/me`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    });

    yield setToken(token);
    yield setUser(userResponse?.data);
    yield put(fromFilterActions.fetchBrandList());

    yield put(push('/dashboard'));
  } catch (error) {
    toast.error(parseErrorMsg(error));
    yield put(fromActions.signInFail(error));
  }
}

export function* signOut() {
  try {
    yield logout();
    window.location.href = process.env.REACT_APP_INSIGHTS_URL;
  } catch (error) {
    toast.error(parseErrorMsg(error));
  }
}

export function* watchAuth() {
  yield takeLatest(fromTypes.SIGN_IN, signIn);
  yield takeLatest(fromTypes.SIGN_OUT, signOut);
  yield takeLatest(fromTypes.SIGN_IN_FROM_OUT, me);
}
