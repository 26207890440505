// @flow
import * as fromTypes from '../types/user';
import { User } from '../../models';

export const userDetails = (id: string): fromTypes.UserAction => ({
  type: fromTypes.USER_DETAILS,
  id
});

export const userDetailsSuccess = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.USER_DETAILS_SUCCESS,
  payload
});

export const userDetailsFail = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.USER_DETAILS_FAIL,
  payload
});

export const updateUser = (user: User): fromTypes.UserAction => ({
  type: fromTypes.UPDATE_USER,
  user
});

export const updateUserSuccess = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.UPDATE_USER_SUCCESS,
  payload
});

export const updateUserFail = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.UPDATE_USER_FAIL,
  payload
});

export const updatePassword = (id: string, password: string): fromTypes.UserAction => ({
  type: fromTypes.UPDATE_PASSWORD,
  id,
  password
});

export const updatePasswordSuccess = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.UPDATE_PASSWORD_SUCCESS,
  payload
});

export const updatePasswordFail = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.UPDATE_PASSWORD_FAIL,
  payload
});

export const fetchUserList = (
  sort: string,
  order: string,
  page: number,
  size: number
): fromTypes.UserAction => ({
  type: fromTypes.FETCH_USER_LIST,
  sort,
  order,
  page,
  size
});

export const fetchUserListSuccess = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.FETCH_USER_LIST_SUCCESS,
  payload
});

export const fetchUserListFail = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.FETCH_USER_LIST_FAIL,
  payload
});

export const addUser = (user: User): fromTypes.UserAction => ({
  type: fromTypes.ADD_USER,
  user
});

export const addUserSuccess = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.ADD_USER_SUCCESS,
  payload
});

export const addUserFail = (payload: any): fromTypes.UserAction => ({
  type: fromTypes.ADD_USER_FAIL,
  payload
});
