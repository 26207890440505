export default {
  THE_SMALLEST: '0.8rem',
  EXTRA_SMALL: '1.2rem',
  TOO_SMALL: '1.5rem',
  SMALL: '1.7rem',
  MEDIUML: '2rem',
  MEDIUM: '2.5rem',
  BIG: '3rem',
  BIG_MEDIUM: '3.1rem',
  VERY_LARGE: '3.5rem',
  EXTRA_BIG: '4rem',
  HUGE: '4.5rem',
  VERY_HUGE: '4.7rem',
  MONSTER: '8rem',
  MEDIUM_MONSTER: '10rem',
  BIG_MONSTER: '12rem'
};
