// @flow
import * as fromTypes from '../types/table';
import { TableSort } from '../../models';

type State = {
  loading: boolean,
  data: Object,
  tableSort: TableSort
};

export const initialState = {
  loading: false,
  data: {},
  tableSort: new TableSort()
};

const reducer = (state: State = initialState, action: fromTypes.TableAction) => {
  switch (action.type) {
    case fromTypes.FETCH_TABLE:
      return {
        ...state,
        tableSort: new TableSort(action),
        loading: true
      };
    case fromTypes.FETCH_TABLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case fromTypes.FETCH_TABLE_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
