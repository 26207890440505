import React, { useCallback } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Chart from '../../../../components/Charts';
import { CustomizedChart } from '../../../../components/Charts/CustomizedChart';
import { blueDefault, darkgray, darkgray01, darkgray02 } from '../../../../assets/variables';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(20% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  },
  chartClass: {
    height: '30rem'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleHeader: {
    color: blueDefault,
    fontWeight: 'bold',
    fontSize: '23px',
    textTransform: 'uppercase'
  },
  subTitleHeader: {
    color: darkgray,
    fontSize: '13px',
    textTransform: 'uppercase'
  },
  detailContainer: {
    marginTop: '1.3rem'
  },
  title: {
    color: darkgray02,
    fontWeight: 'bold',
    marginBottom: '0.4rem'
  },
  itemDetail: {
    display: 'flex',
    fontSize: '14px',
    color: darkgray01,
    marginTop: '0.3rem'
  },
  itemDetailResult: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px'
  },
  percentage: {
    display: 'inline-block',
    fontSize: '15px',
    marginRight: '0.3rem'
  }
}));

const oldQuestions = [
  'O que achou do produto?',
  'Como foi sua experiência com o produto?',
  'Você recomendaria esse produto?'
]

const Wrapper = ({ size, children }) => (
  <Grid item lg={size} xs={12}>
    {children}
  </Grid>
);

const ChartList = props => {
  const classes = useStyles();
  const { chart, chartDetail, totalVotes } = props;

  const getMessage = useCallback((id) => {
    if (id === 3) return `(Sendo 1 não recomendaria e 5 com certeza recomendaria)`;

    if (id === 1) return `(Sendo 1 não gostei e 5 amei muito)`;

    return '';
  }, []);

  function getQuestion() {
    return {
      ...chart,
      answers: chart.data.map(item => ({
        ...item,
        value: item.numVotes,
        percentage: item.value,
        answer: item.category,
      })),
    }
  }

  function reduce(array, field) {
    return array.reduce((acc, next) => acc + next[field], 0)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {!oldQuestions.includes(chart.question)
          ? <Wrapper size={6}>
              <CustomizedChart question={getQuestion()} totalAnswers={reduce(chart.data, 'numVotes')} />
            </Wrapper>
          : <Wrapper size={8}>
            <Chart
              type={'bar'}
              title={`${chart.question} ${getMessage(chart.id)}`}
              data={chart.data}
              classroot={classes.chartClass}
            />
          </Wrapper>
        }
        <Wrapper size={4}>
          {!chartDetail ? (
            <CircularProgress size="5rem" className={classes.loader} />
          ) : (
            <div>
              <div className={classes.headerContainer}>
                <Typography variant="body1" className={classes.titleHeader}>
                  {chartDetail.value}
                  <span className={classes.percentage}>%</span>
                  votou&nbsp;
                  {chartDetail.category}
                </Typography>
                <Typography variant="body1" className={classes.subTitleHeader}>
                  {`Aproximadamente ${chartDetail.numVotes} pessoas de ${totalVotes}`}
                </Typography>
              </div>
              <div className={classes.detailContainer}>
                <Typography variant="body1" className={classes.title}>
                  Informações demográficas
                </Typography>

                <span className={classes.itemDetail}>
                  <span>Idade:</span>
                  <span className={classes.itemDetailResult}>
                    {chartDetail?.year?.map((item, i) => (
                      <span key={`year-list-${i + 1}`}>{`${item.year} - ${item.percentage}%`}</span>
                    ))}
                  </span>
                </span>
              </div>
              <div className={classes.detailContainer}>
                <Typography variant="body1" className={classes.title}>
                  Localização
                </Typography>

                {chartDetail?.localization?.map((item, index) => (
                  <span className={classes.itemDetail} key={`item-detail-${index + 1}`}>
                    {`${item.region}: ${item.percentage}%`}
                  </span>
                ))}
              </div>
            </div>
          )}
        </Wrapper>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const { chartDetail } = state.chart;
  return { chartDetail };
};

export default connect(mapStateToProps)(ChartList);
