import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core';

export default function BackdropView({ children, isOpen }) {
  return (
    <>
      {!isOpen 
        ? children 
        : <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop> 
      }
    </>
  )
}