import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BarChart, Bar, XAxis, LabelList, ResponsiveContainer, Cell } from 'recharts';
import { blueDefault, gray500 } from '../../assets/variables';

import { fetchChartDetail } from '../../store/actions';

const getPosition = value => {
  const isInteger = Number.isInteger(value)
  let position;
  if (isInteger) {
    position = value < 10 ? 10 : value === 100 ? 21 : 15;
  } else {
    position = value < 10 ? 18 : 25
  }
  return position;
}

const renderCustomizedLabel = props => {
  const { x, y, width, value, index, activeIndex } = props;
  return (
    <g>
      <text
        x={x - 7 + width / 2}
        y={y - 10}
        fill={index === activeIndex ? blueDefault : gray500}
        textAnchor="middle"
        dominantBaseline="middle"
        className="text-percentage"
      >
        {value}
      </text>
      <text
        x={x + getPosition(value) + width / 2}
        y={y - 7}
        fill={index === activeIndex ? blueDefault : gray500}
        textAnchor="middle"
        dominantBaseline="middle"
        className="percentage"
      >
        %
      </text>
    </g>
  );
};

const CustomBarChart = props => {
  const { data, onFetchChartDetail } = props;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [isAnimation, setIsAnimation] = React.useState(true);

  const handleClick = (_data, index) => {
    setActiveIndex(index);
    onFetchChartDetail(_data);
  };

  useEffect(() => {
    onFetchChartDetail(data[activeIndex]);
  }, [activeIndex, onFetchChartDetail, data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{top: 30}}>
        <XAxis dataKey="category" axisLine={false} tickLine={false} interval={0} tick={{ fontSize: '14px' }}  />
        <Bar
          dataKey="value"
          fill={gray500}
          onClick={handleClick}
          isAnimationActive={isAnimation}
          onAnimationEnd={() => setIsAnimation(false)}
        >
          <LabelList dataKey="value" activeIndex={activeIndex} content={renderCustomizedLabel} />
          {data.map((_entry, index) => (
            <Cell
              cursor="pointer"
              fill={index === activeIndex ? blueDefault : gray500}
              key={`cell-${index + 1}`}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchChartDetail: payload => dispatch(fetchChartDetail(payload))
  };
};
export default compose(connect(null, mapDispatchToProps))(CustomBarChart);
