// @flow
import decode from 'jwt-decode';

export const APPLICATION_JSON = 'application/json';
export const TEXT_CSV = 'text/csv';

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('@B2B/user');
  localStorage.removeItem('uauBoxB2BBrandId');
};

export const setToken = async token => localStorage.setItem('token', token);

export const getToken = () => localStorage.getItem('token') || '';

export const setUser = async user => {
  localStorage.setItem('@B2B/user', JSON.stringify(user));
  localStorage.setItem('uauBoxB2BBrandId', user.data.brandIds);
};

export const getUser = () => JSON.parse(localStorage.getItem('@B2B/user'));

export const getAuthId = () => {
  const token = decode(getToken());
  return token.userId;
};

export const getBrandId = () => {
  const token = decode(getToken());
  return token.data.brandIds;
};

export const isAuthenticated = () => {
  const token = getToken();
  return !!token;
};

export const isPasswordExpired = () => {
  const token = decode(getToken());
  return token.expiredPassword;
};

export const isAdmin = () => {
  const token = decode(getToken());
  return token.type === 'ROLE_ADMIN';
};

export const getHeaders = (contentType = APPLICATION_JSON) => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json, text/plain, */*',
  'Content-Type': contentType
});

export const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
