const months = {
  '01': 'Jan',
  '02': 'Fev',
  '03': 'Mar',
  '04': 'Abr',
  '05': 'Mai',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Ago',
  '09': 'Set',
  '10': 'Out',
  '11': 'Nov',
  '12': 'Dez'
}

const getEdition = edition => {
  const year = edition.substring(0, 4);
  const month = edition.substring(4, 6);

  return `${months[month]} de ${year}`
}

const convertEditions = (editions) => {
  const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1 < 10 ? `${0}${new Date().getMonth() + 1}` : new Date().getMonth() + 1;

    const fullYearAndMonth = `${currentYear}${currentMonth}`

    return editions.map(edition => ({
      id: edition,
      title: getEdition(edition)
    })).filter(edition => edition.id <= parseInt(fullYearAndMonth))
}

export {
  convertEditions
}
