import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { darkgray02, gray02 } from '../../assets/variables';
import { fetchChartDetail } from '../../store/actions';
import Loading from '../Loading';



const useStyles = makeStyles(theme => ({
  root: () => ({
    background: theme.background ? theme.background : gray02,
    height: '100%',
    padding: theme.spacing(3),
    cursor: 'pointer'
  }),
  title: {
    fontWeight: 'bold',
    color: darkgray02,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#FFF',
    height: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    background: '#fff',
    height: '100%',
    color: '#000',
    borderRadius: 20,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  percentage: ({ percentage }) => ({
    margin: theme.spacing(1),
    background: `linear-gradient(to right, #0D0957 ${0}%, #fff ${percentage}%, #fff ${100 - percentage}%)`,
    border: '1px solid #C8C8C8',
    height: 10,
    borderRadius: 8,
    bottom: 8,
  }),
  answer: {
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  answerCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  votePercentage: {
    fontWeight: '900',
    textAlign: 'center',
    fontSize: '1rem',
    margin: 0,
    marginTop: theme.spacing(1),
    padding: 0,
  },
  votes: {
    margin: 0,
    padding: 0,
    fontWeight: '',
    textAlign: 'center',
    fontSize: '0.6rem'
  },
  gridItem: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

function Card({ answer, setActiveIndex }) {
  const classes = useStyles({ percentage: answer.percentage })

  return (
    <Grid
      xl={3}
      lg={4}
      md={6}
      xs={12}
      onClick={() => setActiveIndex()}
       className={classes.gridItem}
    >
      <Box className={classes.paper}>
        <div className={classes.answerCard}>
          <Typography className={classes.answer}>
            {answer.answer}
          </Typography>
        </div>
        <div>
          <Typography className={classes.votePercentage}>
            {answer.percentage}%
          </Typography>
          <Typography className={classes.votes}>
            {answer.value} voto(s)
          </Typography>
          <Box className={classes.percentage} />
        </div>
      </Box>
    </Grid>
  )
}

export function CustomizedChart({ question, totalAnswers, isLoading }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    dispatch(fetchChartDetail(question.answers[activeIndex]));
  // eslint-disable-next-line
  }, [dispatch, activeIndex]);

  return (
    <div className={classes.root}>
    <Loading isLoading={isLoading} hasData={question?.answers?.length > 0}>
      <div>
        <Typography variant="body1" className={classes.title}>
          {question?.question} - Total de Votos {totalAnswers ?? question.totalAnswers}
        </Typography>
        <Box className={classes.items}>
          {question?.answers?.sort((a, b) => b.percentage - a.percentage)
            .map((answer, index) => (
            <Card setActiveIndex={() => setActiveIndex(index)} key={index} answer={answer} />
          ))}
        </Box>
      </div>
    </Loading>
  </div>
  )
}
