import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

import { fetchChartByState } from '../../store/actions';
import { darkgray02, darkgray01, blueDefault } from '../../assets/variables';

import './index.css';
import MapChart from '../../components/Charts/MapChart';
import SelectEdition from '../../components/SelectEdition/SelectEdition';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(20% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '60%'
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  filterContainer: {
    display: 'flex',
    width: '40%'
  },
  datePicker: {
    margin: '0 0 0 0.6rem',
    '&:first-child': {
      margin: 0
    }
  },
  bodyContainer: {
    marginTop: '3rem'
  },
  detailContainer: {
    marginTop: '1.3rem'
  },
  itemDetail: {
    display: 'flex',
    fontSize: '14px',
    color: darkgray01,
    marginTop: '0.3rem'
  },
  itemDetailResult: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  stateDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 'calc(50% + 3rem)',
    bottom: '-6rem',
    width: '200px'
  },
  stateName: {
    fontSize: '18px',
    color: darkgray02,
    marginBottom: '0.5rem'
  },
  statePercentage: {
    fontSize: '40px',
    color: blueDefault,
    fontWeight: 'bold'
  },
  textStateInfo: {
    fontSize: '16px',
    color: darkgray02
  },
  formControl: {
    margin: '0 0.5rem',
    width: '100%'
  }
}));

const DivisionByState = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { brandSelected, onFetchChartByState, chartMap, loaded } = props;

  const { startEdition, endEdition } = useSelector(state => state.filter);

  const [editionChanged, setEditionChanged] = React.useState(false);
  const [stateSelected, setStateSelected] = React.useState(null);

  const Wrapper = ({ size, children }) => (
    <Grid item lg={size} xs={12} className={classes.gridContainer}>
      {children}
    </Grid>
  );

  const updateInfo = useCallback(() => {
    if (brandSelected.id) {
      onFetchChartByState(brandSelected.id, startEdition, endEdition);
    }
  }, [onFetchChartByState, brandSelected, startEdition, endEdition]);

  useEffect(() => {
    dispatch(fetchChartByState(brandSelected?.id, startEdition, endEdition));

    return;
  }, [dispatch, brandSelected,startEdition, endEdition]);

  const handleSelectState = useCallback(
    (event, _state) => {
      if (event) {
        event.preventDefault();
      }

      const stateSelected = chartMap?.find(state => state.abbreviation === _state);

      setStateSelected(stateSelected ?? {
        abbreviation: _state,
        id: 0,
        info: [],
        name: 'Brasil',
        percentage: 0
      });
    },
    [chartMap]
  );

  useEffect(() => {
    if (brandSelected.id) {
      if (chartMap.length > 0 && !editionChanged) {
        handleSelectState(null, 'SP');
      } else if (editionChanged) {
        updateInfo();
        setEditionChanged(false);
      } else if (!loaded) {
        updateInfo();
      } else if (loaded && chartMap.length === 0) {
        handleSelectState(null, 'SP');
      }
    }
  }, [brandSelected, chartMap, loaded, editionChanged, setEditionChanged, handleSelectState, updateInfo]);

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <div className={classes.titleContainer}>
          <Typography variant="body1" className={classes.title}>
          Distribuição de respondentes por estado
          </Typography>
        </div>
        <SelectEdition />
      </div>
      {!stateSelected ? (
        <CircularProgress size="5rem" className={classes.loader} />
      ) : (
        <Grid container spacing={1} className={classes.bodyContainer}>
          <Wrapper size={8}>
            <MapChart handleSelectState={handleSelectState} stateSelected={stateSelected} chartMap={chartMap} />
            <div className={classes.stateDetail}>
              <span className={classes.stateName}>{stateSelected.name}</span>
              <span className={classes.statePercentage}>{`${stateSelected.percentage}%`}</span>
              <span className={classes.textStateInfo}>das respostas</span>
            </div>
          </Wrapper>
          <Wrapper size={4}>
            <div className={classes.detailContainer}>
              <Typography variant="body1" className={classes.title}>
              Distribuição de respondentes por faixa etária:
              </Typography>

              <span className={classes.itemDetail}>
                {stateSelected.info.length > 0 ? (
                  <React.Fragment>
                    <span className={classes.itemDetailResult}>
                      {stateSelected.info.map((item, i) => (
                        <span key={`year-item-${i + 1}`}>{item.year} - {item.percentage}%</span>
                      ))}
                    </span>
                  </React.Fragment>
                ) : <span>Nenhuma informação encontrada</span> }
              </span>
            </div>
          </Wrapper>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { brandSelected } = state.filter;
  const { loading, loaded, chartMap } = state.chart;
  return { brandSelected, loaded, loading, chartMap };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchChartByState: (brandId, startEdition, endEdition) =>
      dispatch(fetchChartByState(brandId, startEdition, endEdition))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(DivisionByState);
