// @flow
import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Legend } from 'recharts';
import { darkgray02 } from '../../assets/variables';

import './index.css';

const COLORS = [
  '#BADFD8',
  '#3F6669',
  '#0D0957'
];

const CustomPieChart = props => {
  const { data, totalAnswers } = props;

  const renderLegend = p => {
    const { payload } = p;
    return (
      <div>
        {payload.map(item => (
          <div className="legend-container" key={`index-${item.payload.id}`}>
            <span className="dot" style={{ background: item.color }} />
            <div className="description-container">
              <span>{`${item.payload.answer}`}</span>
              <span>{`${item.payload.value}/${item.payload.percentage}%`}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return data.length ? (
    <ResponsiveContainer width="100%" height="90%">
      <PieChart>
        <Pie dataKey="value" data={data} innerRadius="70%" outerRadius="100%" paddingAngle={0}>
          {data.map((entry, index) => (
            <Cell key={`cel-${index + 1}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <text
          style={{ fontSize: '2.8rem', fill: darkgray02 }}
          x="50%"
          y="33%"
          dy={8}
          textAnchor="middle"
          fill={darkgray02}
        >
          {totalAnswers}
        </text>
        <text
          style={{ fontSize: '0.7rem' }}
          x="50%"
          y="38%"
          dy={8}
          textAnchor="middle"
          fill={darkgray02}
        >
          pessoas responderam
        </text>
        <Legend height={100} layout="vertical" content={renderLegend} />
      </PieChart>
    </ResponsiveContainer>
  ) : null;
};
export default CustomPieChart;
