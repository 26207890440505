// @flow
import * as fromTypes from '../types/filter';

export const fetchBrandList = () => ({
  type: fromTypes.FETCH_BRAND_LIST
});

export const fetchBrandListSuccess = payload => ({
  type: fromTypes.FETCH_BRAND_LIST_SUCCESS,
  payload
});

export const fetchBrandListFail = payload => ({
  type: fromTypes.FETCH_BRAND_LIST_FAIL,
  payload
});

export const fetchProductList = brandId => ({
  type: fromTypes.FETCH_PRODUCT_LIST,
  brandId
});

export const fetchProductListSuccess = payload => ({
  type: fromTypes.FETCH_PRODUCT_LIST_SUCCESS,
  payload
});

export const fetchProductListFail = payload => ({
  type: fromTypes.FETCH_PRODUCT_LIST_FAIL,
  payload
});

export const fetchStateList = () => ({
  type: fromTypes.FETCH_STATE_LIST
});

export const fetchStateListSuccess = payload => ({
  type: fromTypes.FETCH_STATE_LIST_SUCCESS,
  payload
});

export const fetchStateListFail = payload => ({
  type: fromTypes.FETCH_STATE_LIST_FAIL,
  payload
});

export const selectBrand = payload => ({
  type: fromTypes.SELECT_BRAND,
  payload
});

export const saveInitialEdition = ({ startEdition }) => ({
  type: fromTypes.SAVE_INITIAL_EDITION,
  payload: { startEdition }
});

export const saveFinalEdition = ({ endEdition }) => ({
  type: fromTypes.SAVE_FINAL_EDITION,
  payload: { endEdition }
});
