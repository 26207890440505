export const blue100 = '#F8FAFC';
export const blue200 = '#F6F8FD';
export const purple500 = '#4035DD';
export const gray500 = '#DFE3E8';
export const white = '#FFFFFF';
export const black = '#000000';

export const gray01 = '#F5F5F5';
export const gray02 = '#EFEFEF';
export const darkgray = '#C6C6C6';
export const darkgray01 = '#939393';
export const darkgray02 = '#585858';

export const redDefault = '#FA403C';

export const blueDefault = '#000E30';

export const footerDefault = '#D59C36';
export const footerGray = '#ACACAC';
