import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { push } from 'react-router-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  Button,
  Grid,
  MenuItem
} from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ReactDropzone from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { fetchSaveEmailMarketing } from '../../../store/actions';

import {
  white,
  gray01,
  gray02,
  darkgray01,
  darkgray02,
  blueDefault
} from '../../../assets/variables';
import { renderTextField, renderSelectField } from '../../../components/wrapper';

import headerEmkt from '../../../assets/img/header-emkt.png';
import footerEmkt from '../../../assets/img/footer-emkt.png';
import iconEmkt from '../../../assets/img/icon-mkt.png';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './index.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(20% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '60%'
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  titleContainerSaved: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: '2rem'
  },
  titleSaved: {
    width: '100%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(1.9),
    textAlign: 'center',
    paddingBottom: '0.8rem',
    borderBottom: `2px solid ${blueDefault}`
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    '& button': {
      minWidth: '200px',
      textTransform: 'lowercase',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: white,
      color: darkgray02,
      fontWeight: 'bold',
      border: `1px solid ${darkgray01}`,
      boxShadow: 'none',
      '&:hover': {
        background: gray01,
        color: darkgray02,
        border: `1px solid ${darkgray01}`,
        boxShadow: 'none'
      }
    }
  },
  buttonNext: {
    background: `${blueDefault} !important`,
    color: `${white} !important`,
    border: `1px solid ${blueDefault} !important`
  },
  iconLeft: {
    marginLeft: '-10px'
  },
  iconRight: {
    marginRight: '-10px'
  },
  tabs: {
    marginTop: '2rem',
    '& button': {
      textTransform: 'none'
    }
  },
  bodyContainer: {
    margin: '0 auto',
    width: '679px'
  },
  headerEmkt: {
    display: 'block',
    margin: '0 auto'
  },
  footerEmkt: {
    display: 'block',
    margin: '1rem auto 0'
  },
  moreComponents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem'
  },
  titleComponents: {
    color: darkgray02,
    fontWeight: 'bold',
    fontSize: '15px',
    marginBottom: '0.5rem'
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btnOption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0.5rem',
    cursor: 'pointer',
    background: 'none',
    border: 0,
    outline: 'none',
    '& svg': {
      padding: '1.5rem',
      borderRadius: '50%',
      background: gray02,
      color: darkgray02,
      fontSize: '25px'
    },
    '& span': {
      color: darkgray02,
      fontSize: '14px'
    }
  },
  editorTextArea: {
    border: `1px solid ${gray02}`,
    padding: '5px'
  },
  componetContainer: {
    position: 'relative'
  },
  btnRemoveCompoent: {
    position: 'absolute',
    display: 'flex',
    right: '-24px',
    background: '#D85440',
    border: 'none',
    color: white,
    outline: 'none',
    opacity: 0.5,
    padding: '2px',
    borderRadius: '0 5px 5px 0',
    fontSize: '12px',
    transition: 'opacity ease 0.4s',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    },
    '& svg': {
      color: white,
      fontSize: '20px'
    }
  },
  dragDropContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: gray02,
    width: '100%',
    padding: '100px 0',
    margin: '0.5rem 0'
  },
  dragDropInput: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    '& svg': {
      color: darkgray01,
      fontSize: '30px',
      marginRight: '0.5rem'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  dragInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      color: darkgray01,
      fontSize: '11px'
    }
  },
  dragTitle: {
    fontSize: '12px !important',
    fontWeight: 'bold'
  },
  bannerImage: {
    width: '100%'
  },
  withoutFocus: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    }
  },
  bodyContainerSaved: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3.5rem',
    '& img': {
      width: '60px'
    }
  },
  titleBodySaved: {
    fontSize: '35px',
    fontWeight: 'bold',
    color: darkgray02,
    marginTop: '1.5rem',
    marginBottom: '0.8rem'
  },
  descriptionSaved: {
    fontSize: '18px',
    color: darkgray01,
    margin: '0.1rem 0'
  },
  buttonNextSaved: {
    marginTop: '2.5rem',
    background: `${blueDefault} !important`,
    color: `${white} !important`,
    border: `1px solid ${blueDefault} !important`,
    textTransform: 'initial',
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  loaderSave: {
    color: white,
    margin: '0 auto',
    padding: '0.18rem 0'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span" variant="body1">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Wrapper = ({ children }) => (
  <Grid item lg={12} xs={12}>
    {children}
  </Grid>
);

const validate = values => {
  const errors = {};
  ['campaignName', 'subject', 'sendersName', 'sendersEmail', 'targetAudience'].forEach(field => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
  });
  if (
    values.sendersEmail &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.sendersEmail)
  ) {
    errors.sendersEmail = 'Endereço de email invalido';
  }
  return errors;
};

const EmailMarketingNew = props => {
  const classes = useStyles();

  const {
    brandSelected,
    loading,
    handleSubmit,
    submitting,
    campaignName,
    subject,
    sendersName,
    sendersEmail,
    targetAudience,
    initialize,
    dispatch,
    saveLoading,
    isSaved,
    onFetchSaveEmailMarketing
  } = props;
  const targetAudienceList = [
    { id: 1, name: 'Toda a base' },
    { id: 2, name: 'Pessoas interessadas em produtos para a pele' },
    { id: 3, name: 'Pessoas interessadas em produtos para o cabelo' }
  ];

  const [value, setValue] = React.useState(0);
  const [components, setComponents] = React.useState([]);

  useEffect(() => {
    initialize({ targetAudience: 1 });
  }, [initialize]);

  const onSubmit = data => setValue(value + 1);
  const handleBack = () => setValue(value - 1);
  const handleNextStep = () => setValue(value + 1);

  const setComponent = type => {
    setComponents(oldComponents => [
      ...oldComponents,
      ...[{ type, text: EditorState.createEmpty(), image: '' }]
    ]);
  };

  const handleRemoveComponet = index => {
    const updateComponent = [...components];
    updateComponent.splice(index, 1);
    setComponents(updateComponent);
  };

  const onEditorStateChange = (event, index) => {
    const updateComponent = [...components];
    updateComponent[index].text = event;

    setComponents(updateComponent);
  };

  const onPreviewDrop = (files, index) => {
    const file = files[0];
    const updateComponent = [...components];
    updateComponent[index].image = file;
    updateComponent[index].preview = URL.createObjectURL(file);
    updateComponent[index].text = '';

    setComponents(updateComponent);
  };

  const redirectToLsit = () => {
    dispatch(push('/email-marketing'));
  };

  const save = () => {
    const newComponents = cloneDeep(components);
    for (let i = 0; i < newComponents.length; i += 1) {
      if (newComponents[i].type === 'text') {
        const text = draftToHtml(convertToRaw(newComponents[i].text.getCurrentContent()));
        newComponents[i].text = text;
      }
    }
    const obj = {
      brandId: brandSelected.id,
      campaignName,
      subject,
      sendersName,
      sendersEmail,
      targetAudience,
      components: newComponents
    };

    dispatch(onFetchSaveEmailMarketing(obj));
  };

  return (
    <div>
      {!isSaved ? (
        <div className={classes.root}>
          <div className={classes.pageHeader}>
            <div className={classes.titleContainer}>
              <Typography variant="body1" className={classes.title}>
                Nova campanha
              </Typography>
            </div>
          </div>
          {loading ? (
            <CircularProgress size="5rem" className={classes.loader} />
          ) : (
            <div>
              <Tabs
                className={classes.tabs}
                variant="fullWidth"
                value={value}
                aria-label="Questionarios Marca"
                indicatorColor="primary"
              >
                <Tab label="Criar campanha" {...a11yProps(1)} disabled={value !== 0} />
                <Tab label="Template" {...a11yProps(2)} disabled={value !== 1} />
                <Tab label="Revisão" {...a11yProps(3)} disabled={value !== 2} />
              </Tabs>
              <TabPanel value={value} index={0} key={`tabPanel-${1}`}>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
                  <Grid container spacing={1}>
                    <Wrapper>
                      <Field
                        name="campaignName"
                        type="text"
                        placeholder="Nome da Campanha"
                        component={renderTextField}
                        className={classes.textField}
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="subject"
                        type="text"
                        placeholder="Assunto do e-mail"
                        component={renderTextField}
                        className={classes.textField}
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="sendersName"
                        type="text"
                        placeholder="Nome do Remetente"
                        component={renderTextField}
                        className={classes.textField}
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="sendersEmail"
                        type="text"
                        placeholder="E-mail do Remetente"
                        component={renderTextField}
                        className={classes.textField}
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="targetAudience"
                        component={renderSelectField}
                        className={classes.textField}
                      >
                        <MenuItem value="" disabled>
                          Público
                        </MenuItem>
                        {targetAudienceList.map((i, index) => (
                          <MenuItem key={`key-${index + 1}`} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Wrapper>
                  </Grid>
                  <div className={classes.buttonsContainer}>
                    <span>&nbsp;</span>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      variant="contained"
                      className={classes.buttonNext}
                    >
                      Próximo
                      <ArrowRight className={classes.iconRight} />
                    </Button>
                  </div>
                </form>
              </TabPanel>
              <TabPanel value={value} index={1} key={`tabPanel-${2}`}>
                <div className={classes.bodyContainer}>
                  <img src={headerEmkt} alt="Header" className={classes.headerEmkt} />

                  {components.map((component, index) => (
                    <div
                      className={classes.componetContainer}
                      key={`${component.type}-${index + 1}`}
                    >
                      <button
                        type="button"
                        className={classes.btnRemoveCompoent}
                        onClick={() => {
                          handleRemoveComponet(index);
                        }}
                      >
                        <CloseIcon />
                      </button>
                      {component.type === 'text' ? (
                        <Editor
                          editorState={component.text}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName={classes.editorTextArea}
                          onEditorStateChange={event => {
                            onEditorStateChange(event, index);
                          }}
                          toolbar={{
                            options: [
                              'inline',
                              'blockType',
                              'fontSize',
                              'fontFamily',
                              'list',
                              'textAlign',
                              'link',
                              'history'
                            ]
                          }}
                        />
                      ) : (
                        <div>
                          {component && !component.preview ? (
                            <ReactDropzone
                              accept="image/*"
                              multiple={false}
                              onDrop={event => {
                                onPreviewDrop(event, index);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className={classes.dragDropContainer}>
                                  <div {...getRootProps()} className={classes.withoutFocus}>
                                    <input {...getInputProps()} />
                                    <div className={classes.dragDropInput}>
                                      <CloudUploadIcon />
                                      <div className={classes.dragInfo}>
                                        <span className={classes.dragTitle}>Adicionar banner</span>
                                        <span>Largura 679px</span>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ReactDropzone>
                          ) : (
                            <ReactDropzone
                              accept="image/*"
                              multiple={false}
                              onDrop={event => {
                                onPreviewDrop(event, index);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className={classes.withoutFocus}>
                                  <input {...getInputProps()} />
                                  <img
                                    alt="Preview"
                                    key={component.preview}
                                    src={component.preview}
                                    className={classes.bannerImage}
                                  />
                                </div>
                              )}
                            </ReactDropzone>
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                  <div className={classes.moreComponents}>
                    <span className={classes.titleComponents}>Adicionar mais componentes</span>

                    <div className={classes.optionsContainer}>
                      <button
                        type="button"
                        className={classes.btnOption}
                        onClick={() => {
                          setComponent('image');
                        }}
                      >
                        <CloudUploadIcon />
                        <span>Imagem</span>
                      </button>
                      <button
                        type="button"
                        className={classes.btnOption}
                        onClick={() => {
                          setComponent('text');
                        }}
                      >
                        <TextFieldsIcon />
                        <span>Texto</span>
                      </button>
                    </div>
                  </div>

                  <Divider />
                  <img src={footerEmkt} alt="Footer" className={classes.footerEmkt} />
                </div>
                <div className={classes.buttonsContainer}>
                  <Button
                    color="primary"
                    disabled={submitting}
                    variant="text"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    <ArrowLeft className={classes.iconLeft} />
                    Voltar
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    variant="contained"
                    className={classes.buttonNext}
                    onClick={handleNextStep}
                  >
                    Próximo
                    <ArrowRight className={classes.iconRight} />
                  </Button>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2} key={`tabPanel-${3}`}>
                <div>
                  <Grid container spacing={1}>
                    <Wrapper>
                      <Field
                        name="campaignName"
                        type="text"
                        placeholder="Nome da Campanha"
                        component={renderTextField}
                        className={classes.textField}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="subject"
                        type="text"
                        placeholder="Assunto do e-mail"
                        component={renderTextField}
                        className={classes.textField}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="sendersName"
                        type="text"
                        placeholder="Nome do Remetente"
                        component={renderTextField}
                        className={classes.textField}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="sendersEmail"
                        type="text"
                        placeholder="E-mail do Remetente"
                        component={renderTextField}
                        className={classes.textField}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper>
                      <Field
                        name="targetAudience"
                        component={renderSelectField}
                        className={classes.textField}
                        disabled
                      >
                        <MenuItem value="" disabled>
                          Público
                        </MenuItem>
                        {targetAudienceList.map((i, index) => (
                          <MenuItem key={`key-${index + 1}`} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Wrapper>
                  </Grid>
                  <div className={classes.bodyContainer}>
                    <img src={headerEmkt} alt="Header" className={classes.headerEmkt} />

                    {components.map((component, index) => (
                      <div key={`${component.type}-${index + 1}`}>
                        {component.type === 'text' && value === 2 ? (
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(convertToRaw(component.text.getCurrentContent()))
                            }}
                          />
                        ) : (
                          <img
                            alt="Preview"
                            key={component.preview}
                            src={component.preview}
                            className={classes.bannerImage}
                          />
                        )}
                      </div>
                    ))}
                    <Divider />
                    <img src={footerEmkt} alt="Footer" className={classes.footerEmkt} />
                  </div>
                  <div className={classes.buttonsContainer}>
                    <Button
                      color="primary"
                      disabled={submitting}
                      variant="text"
                      onClick={handleBack}
                      className={classes.button}
                    >
                      <ArrowLeft className={classes.iconLeft} />
                      Voltar
                    </Button>
                    {!saveLoading ? (
                      <Button
                        color="primary"
                        type="button"
                        variant="contained"
                        className={classes.buttonNext}
                        onClick={save}
                      >
                        Concluir
                        <ArrowRight className={classes.iconRight} />
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        type="button"
                        variant="contained"
                        className={classes.buttonNext}
                      >
                        <CircularProgress size="1.2rem" className={classes.loaderSave} />
                      </Button>
                    )}
                  </div>
                </div>
              </TabPanel>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.pageHeader}>
            <div className={classes.titleContainerSaved}>
              <Typography variant="body1" className={classes.titleSaved}>
                Concluído
              </Typography>
            </div>
          </div>
          <div className={classes.bodyContainerSaved}>
            <img src={iconEmkt} alt="icon" />
            <Typography variant="body1" className={classes.titleBodySaved}>
              Seu e-mail foi enviado para aprovação!
            </Typography>
            <Typography variant="body1" className={classes.descriptionSaved}>
              Seu e-mail será avaliado por nossa equipe técnica e te avisaremos assim que for
              aprovado!
            </Typography>
            <Typography variant="body1" className={classes.descriptionSaved}>
              Você pode conferir o status da aprovação através do painel principal.
            </Typography>
            <Button
              color="primary"
              type="button"
              variant="contained"
              className={classes.buttonNextSaved}
              onClick={redirectToLsit}
            >
              Ir para o painel
              <ArrowRight className={classes.iconRight} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const selector = formValueSelector('newEmkt');

const mapStateToProps = state => {
  const { brandSelected } = state.filter;
  const { isSaved, saveLoading } = state.emailMarketing;
  const { campaignName, subject, sendersName, sendersEmail, targetAudience } = selector(
    state,
    'campaignName',
    'subject',
    'sendersName',
    'sendersEmail',
    'targetAudience'
  );
  return {
    brandSelected,
    campaignName,
    subject,
    sendersName,
    sendersEmail,
    targetAudience,
    isSaved,
    saveLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSaveEmailMarketing: obj => dispatch(fetchSaveEmailMarketing(obj))
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'newEmkt',
    validate
  })
)(EmailMarketingNew);
