import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API
});

const socialCommerceApi = axios.create({
  baseURL: process.env.REACT_APP_SOCIAL_COMMERCE_CLIENT_URL
});

const inventoryApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API}/inventory`
});

export { socialCommerceApi, inventoryApi };

export default api;
