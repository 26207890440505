import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress, Divider, Button, MenuItem, Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { push } from 'react-router-redux';
import Pagination from 'react-js-pagination';
import { fetchEmailMarketingList } from '../../store/actions';
import {
  white,
  blueDefault,
  footerDefault,
  gray02,
  darkgray01,
  darkgray02
} from '../../assets/variables';
import './index.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(20% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      color: white,
      background: blueDefault,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textTransform: 'initial',
      fontWeight: 'bold',
      boxShadow: 'none',
      '&:hover': {
        background: footerDefault
      },
      '& svg': {
        marginLeft: '15px',
        color: white,
        fontSize: '13px'
      }
    }
  },
  title: {
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  tablecontainer: {
    marginTop: '2rem',
    '& table': {
      width: '100%',
      marginBottom: '1.5rem',
      '& thead': {
        background: gray02,
        color: darkgray02,
        '& tr th': {
          padding: '8px 5px',
          fontSize: '14px'
        }
      },
      '& tbody': {
        color: darkgray02,
        '& tr td': {
          padding: '8px 5px',
          '& div': {
            fontSize: '16px'
          },
          '& span': {
            fontSize: '12px'
          }
        }
      }
    }
  },
  campaignName: {
    width: '32%'
  },
  subject: {
    width: '32%'
  },
  status: {
    width: '23%'
  },
  action: {
    width: '13%'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.5rem',
    '& span': {
      fontSize: '14px',
      color: darkgray01
    }
  },
  btnActions: {
    margin: '0 auto',
    color: white,
    background: blueDefault,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'initial',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      background: footerDefault
    },
    '& svg': {
      color: white,
      fontSize: '15px'
    }
  },
  menuOptions: {
    '& div': {
      background: blueDefault,
      color: white
    }
  }
}));

const EmailMarketing = props => {
  const classes = useStyles();

  const {
    onFetchEmailMarketingList,
    brandSelected,
    loading,
    emailMarketingList,
    total,
    dispatch
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activePage, setActivePage] = React.useState(1);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectBrand = brandId => {
    setAnchorEl(null);
  };

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };

  const handleGoNew = () => {
    dispatch(push('email-marketing/novo'));
  };

  useEffect(() => {
    onFetchEmailMarketingList(brandSelected.id, activePage);
  }, [brandSelected.id, onFetchEmailMarketingList, activePage]);

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <Typography variant="body1" className={classes.title}>
          E-mail marketing
        </Typography>
        <Button variant="contained" onClick={handleGoNew}>
          Nova campanha
          <AddIcon />
        </Button>
      </div>
      <div className={classes.tablecontainer}>
        <table>
          <thead>
            <tr>
              <th>Nome da campanha</th>
              <th>Assunto</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={4}>
                  <CircularProgress size="5rem" className={classes.loader} />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {emailMarketingList.map(emkt => (
                <tr>
                  <td className={classes.campaignName}>
                    <div>{emkt.name}</div>
                    <span>{emkt.created}</span>
                  </td>
                  <td className={classes.subject}>
                    <div>{emkt.subject}</div>
                  </td>
                  <td className={classes.status}>
                    <div>{emkt.status}</div>
                    <span>{emkt.lastUpdate}</span>
                  </td>
                  <td className={classes.action}>
                    <Button className={classes.btnActions} onClick={handleClick}>
                      Selecione
                      <ExpandMoreIcon />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      className={classes.menuOptions}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleSelectBrand(emkt.id);
                        }}
                      >
                        Editar
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleSelectBrand(emkt.id);
                        }}
                      >
                        Estatisticas
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <Divider />
        <div className={classes.paginationContainer}>
          <span>
            {`${total} `}
            e-mails marketing
          </span>
          <Pagination
            hideFirstLastPages
            prevPageText="anterior"
            nextPageText="próximo"
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { brandSelected } = state.filter;
  const { emailMarketingList, total, loading } = state.emailMarketing;
  return { brandSelected, emailMarketingList, total, loading };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onFetchEmailMarketingList: (brandId, pageIndex) =>
      dispatch(fetchEmailMarketingList(brandId, pageIndex))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(EmailMarketing);
