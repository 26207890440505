// @flow
export const FETCH_TABLE = '[TABLE] Fetch table';
export const FETCH_TABLE_SUCCESS = '[TABLE] Fetch table Success';
export const FETCH_TABLE_FAIL = '[TABLE] Fetch table Fail';

export const EXPORT_CSV = '[TABLE] Export CSV';
export const EXPORT_CSV_SUCCESS = '[TABLE] Export CSV Success';
export const EXPORT_CSV_FAIL = '[TABLE] Export CSV Fail';

type FetchTable = {
  type: typeof FETCH_TABLE,
  sort: string,
  order: string,
  page: number,
  size: number
};
type FetchTableSuccess = { type: typeof FETCH_TABLE_SUCCESS, payload: any };
type FetchTableFail = { type: typeof FETCH_TABLE_FAIL, payload: any };

type ExportCsv = { type: typeof EXPORT_CSV };
type ExportCsvSuccess = { type: typeof EXPORT_CSV_SUCCESS, payload: any };
type ExportCsvFail = { type: typeof EXPORT_CSV_FAIL, payload: any };

export type TableAction =
  | FetchTable
  | FetchTableSuccess
  | FetchTableFail
  | ExportCsv
  | ExportCsvSuccess
  | ExportCsvFail;
