import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import filter from './filter';
import table from './table';
import user from './user';
import chart from './chart';
import company from './company';
import emailMarketing from './emailMarketing';
import edition from './edition';
import socialcommerce from './socialcommerce';

const rootReducer = combineReducers({
  routing: routerReducer,
  form: formReducer,
  auth,
  filter,
  table,
  user,
  chart,
  company,
  emailMarketing,
  edition,
  socialcommerce
});

export default rootReducer;
