import React from 'react';
import 'dotenv/config';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './Routes/ProtectedRoute';
import SignIn from '../Sign-in';
import Dashboard from '../Dashboard';
import Campaigns from '../Campaigns';
import BrandData from '../BrandData';
import DivisionByState from '../DivisionByState';
import EmailMarketing from '../EmailMarketing';
import EmailMarketingNew from '../EmailMarketing/newEmkt';
import Product from '../Product';
import NotFound from '../../components/NotFound';
import { UserList, UserDetails, ChangePassword, NewUser } from '../User';
import 'react-multi-carousel/lib/styles.css';

const App = props => {
  const { history } = props;

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path='/' component={() => { 
              window.location.href = process.env.REACT_APP_INSIGHTS_URL; 
              return null;
          }}/>
          <Route exact path="/sign-in" component={SignIn} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/dashboard/:token" component={Dashboard} />
          <ProtectedRoute exact path="/campanhas" component={Campaigns} />
          <ProtectedRoute exact path="/dados" component={BrandData} />
          <ProtectedRoute exact path="/divisao-por-estado" component={DivisionByState} />
          <ProtectedRoute exact path="/produto/:id" component={Product} />
          <ProtectedRoute exact path="/email-marketing" component={EmailMarketing} />
          <ProtectedRoute exact path="/email-marketing/novo" component={EmailMarketingNew} />
          <ProtectedRoute exact path="/email-marketing/:idEmkt/editar" component={EmailMarketing} />
          <ProtectedRoute
            exact
            path="/email-marketing/:idEmkt/estatistica"
            component={EmailMarketing}
          />
          <ProtectedRoute exact path="/users/list" admin component={UserList} />
          <ProtectedRoute exact path="/users/new" admin component={NewUser} />
          <ProtectedRoute exact path="/users/details/:id" admin component={UserDetails} />
          <ProtectedRoute exact path="/users/changePassword/:id" admin component={ChangePassword} />
          <Route component={NotFound} />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  );
};

export default App;
