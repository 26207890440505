// @flow
import * as fromTypes from '../types/company';

export const fetchCompanyList = (): fromTypes.CompanyAction => ({
  type: fromTypes.FETCH_COMPANY_LIST
});

export const fetchCompanyListSuccess = (payload: any): fromTypes.CompanyAction => ({
  type: fromTypes.FETCH_COMPANY_LIST_SUCCESS,
  payload
});

export const fetchCompanyListFail = (payload: any): fromTypes.CompanyAction => ({
  type: fromTypes.FETCH_COMPANY_LIST_FAIL,
  payload
});
