// @flow
import * as fromTypes from '../types/filter';

export const initialState = {
  loading: false,
  brandList: [],
  brandSelected: { id: null, name: '', img: '' },
  productList: [],
  stateList: [],
  startEdition: '',
  endEdition: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromTypes.FETCH_BRAND_LIST:
    case fromTypes.FETCH_PRODUCT_LIST:
    case fromTypes.FETCH_STATE_LIST:
      return {
        ...state,
        loading: true
      };
    case fromTypes.FETCH_BRAND_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        brandList: action.payload.response,
        brandSelected: action.payload.brand
      };
    case fromTypes.FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: action.payload
      };
    case fromTypes.FETCH_STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stateList: action.payload
      };
    case fromTypes.FETCH_BRAND_LIST_FAIL:
    case fromTypes.FETCH_PRODUCT_LIST_FAIL:
    case fromTypes.FETCH_STATE_LIST_FAIL:
      return {
        ...state,
        loading: false
      };
    case fromTypes.SELECT_BRAND:
      return {
        ...state,
        brandSelected: action.payload
      };
    case fromTypes.SAVE_INITIAL_EDITION:
      return {
        ...state,
        startEdition: action.payload.startEdition
      };
    case fromTypes.SAVE_FINAL_EDITION:
      return {
        ...state,
        endEdition: action.payload.endEdition
      };
    default:
      return state;
  }
};

export default reducer;
