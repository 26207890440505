// @flow
import * as fromTypes from '../types/emailMarketing';

export const fetchEmailMarketingList = (brandId, pageIndex) => ({
  type: fromTypes.FETCH_EMAIL_MARKETING_LIST,
  brandId,
  pageIndex
});

export const fetchEmailMarketingListSuccess = payload => ({
  type: fromTypes.FETCH_EMAIL_MARKETING_LIST_SUCCESS,
  payload
});

export const fetchEmailMarketingListFail = payload => ({
  type: fromTypes.FETCH_EMAIL_MARKETING_LIST_FAIL,
  payload
});

export const fetchSaveEmailMarketing = obj => ({
  type: fromTypes.FETCH_SAVE_EMAIL_MARKETING,
  obj
});

export const fetchSaveEmailMarketingSuccess = payload => ({
  type: fromTypes.FETCH_SAVE_EMAIL_MARKETING_SUCCESS,
  payload
});

export const fetchSaveEmailMarketingFail = payload => ({
  type: fromTypes.FETCH_SAVE_EMAIL_MARKETING_FAIL,
  payload
});
