// @flow
import * as fromTypes from '../types/table';

export const fetchTable = (
  sort: string = '',
  order: string = 'asc',
  page: number = 0,
  size: number = 5
): fromTypes.TableAction => ({
  type: fromTypes.FETCH_TABLE,
  sort,
  order,
  page,
  size
});

export const fetchTableSuccess = (payload: any): fromTypes.TableAction => ({
  type: fromTypes.FETCH_TABLE_SUCCESS,
  payload
});

export const fetchTableFail = (payload: any): fromTypes.TableAction => ({
  type: fromTypes.FETCH_TABLE_FAIL,
  payload
});

export const exportCsv = (): fromTypes.TableAction => ({
  type: fromTypes.EXPORT_CSV
});

export const exportCsvSuccess = (payload: any): fromTypes.TableAction => ({
  type: fromTypes.EXPORT_CSV_SUCCESS,
  payload
});

export const exportCsvFail = (payload: any): fromTypes.TableAction => ({
  type: fromTypes.EXPORT_CSV_FAIL,
  payload
});
