import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { change } from 'redux-form';
import { isEmpty } from 'lodash';
import * as fromTypes from '../types/filter';
import * as fromActions from '../actions';
import { getHeaders } from '../services/auth';

const baseUrl = `${process.env.REACT_APP_BASE_API}/feedback`;
const noDataOption = [{ sku: 'none', name: 'Sem Resultados' }];

export const getFilter = state => state.filter;

export function* fetchBrandList() {
  try {
    const response = yield axios.get(`${baseUrl}/brands`, { headers: getHeaders() });

    if (!isEmpty(response.data) && response.status === 200) {
      const user = JSON.parse(localStorage.getItem('@B2B/user'));

      const isAdmin = user.type === 'ROLE_ADMIN';

      const { brandIds } = user.data;

      const brands = isAdmin
        ? response.data
        : response.data.filter(item => brandIds.includes(item.id));

      const brand = isAdmin ? response.data.find(item => item.name === 'UAUBox') : brands[0];

      if (brand.id) {
        yield put(fromActions.fetchProductList(brand.id));
        yield put(fromActions.fetchBrandListSuccess({ response: brands, brand }));
      }
    }
  } catch (error) {
    yield put(fromActions.fetchBrandListFail(error));
  }
}

export function* fetchProductList({ brandId }) {
  try {
    const response = yield axios.get(`${baseUrl}/products?brandId=${brandId}`, {
      headers: getHeaders()
    });

    if (response.status === 200 && !isEmpty(response.data) && response.data.length) {
      const productId = response.data[0].sku;
      if (productId) {
        yield put(change('filter', 'product', productId));
        yield put(fromActions.fetchProductListSuccess(response.data));
      }
    } else {
      yield put(change('filter', 'product', noDataOption[0].sku));
      yield put(fromActions.fetchProductListSuccess(noDataOption));
    }
  } catch (error) {
    yield put(fromActions.fetchProductListFail(error));
  }
}

export function* watchFilter() {
  yield takeLatest(fromTypes.FETCH_BRAND_LIST, fetchBrandList);
  yield takeLatest(fromTypes.FETCH_PRODUCT_LIST, fetchProductList);
}
