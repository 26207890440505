import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { isEmpty } from 'lodash';
import * as fromTypes from '../types/emailMarketing';
import * as fromActions from '../actions';
import { getHeaders } from '../services/auth';

const baseUrl = `${process.env.REACT_APP_BASE_API}/emkt`;

export function* fetchEmailMarketingList({ brandId, pageIndex }) {
  try {
    const response = yield axios.get(
      `${baseUrl}/getEmailMarketing?brandId=${brandId}&pageIndex=${pageIndex}`,
      {
        headers: getHeaders()
      }
    );
    if (!isEmpty(response.data) && response.status === 200) {
      yield put(fromActions.fetchEmailMarketingListSuccess({ response: response.data }));
    }
  } catch (error) {
    const response = {
      total: 2,
      data: [
        {
          id: 1,
          name: 'Campanha de natal',
          created: '20/12 às 10h',
          subject: 'Campanha de natal',
          status: 'Em aprovação',
          lastUpdate: '20/12 às 10h'
        },
        {
          id: 2,
          name: 'Campanha de páscoa',
          created: '20/12 às 10h',
          subject: 'Feliz páscoa',
          status: 'Enviado',
          lastUpdate: '20/12 às 10h'
        }
      ]
    };
    yield put(fromActions.fetchEmailMarketingListSuccess(response));
    // yield put(fromActions.fetchEmailMarketingListFail(error));
  }
}

export function* fetchSaveEmailMarketing({ obj }) {
  try {
    const response = yield axios.post(`${baseUrl}/saveEmailMarketing`, obj, {
      headers: getHeaders()
    });
    if (!isEmpty(response.data) && response.status === 200) {
      yield put(fromActions.fetchSaveEmailMarketingSuccess({ response: response.data }));
    }
  } catch (error) {
    yield put(fromActions.fetchSaveEmailMarketingSuccess({ response: true }));
    // yield put(fromActions.ffetchSaveEmailMarketingFail(error));
  }
}

export function* watchEmailMarketing() {
  yield takeLatest(fromTypes.FETCH_EMAIL_MARKETING_LIST, fetchEmailMarketingList);
  yield takeLatest(fromTypes.FETCH_SAVE_EMAIL_MARKETING, fetchSaveEmailMarketing);
}
