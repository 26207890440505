import { put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as fromTypes from '../types/edition';
import * as fromActions from '../actions/edition';

import api from '../../services/api';

const parseErrorMsg = error => {
  return error.response.data.message
    ? error.response.data.message
    : 'Não foi possivel se conectar ao servidor';
};

export function* editionsRequest() {
  try {
    const response = yield api.get('feedback/editions');

    if (response.status === 200) {
      yield put(fromActions.editionsRequestSuccess(response.data));
    }
  } catch (error) {
    toast.error(parseErrorMsg(error));
  }
}

export function* watchEdition() {
  yield takeLatest(fromTypes.EDITIONS_REQUEST, editionsRequest);
}
