import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, Box } from '@material-ui/core';

import { darkgray02 } from '../../assets/variables';
import SelectEdition from '../../components/SelectEdition/SelectEdition';

import FullScreenDialog from './FullScreenDialog';
import ConfirmActionUpdateStatusDialog from './ConfirmActionUpdateStatusDialog';

import { getCampaignsRequest, getCampaignRequest } from '../../store/actions/socialcommerce';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    width: '60%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  button: {
    width: 100,
    fontSize: '0.8rem',
    marginBottom: 10,
    marginRight: 10,
    marginLeft: 10
  },
  box: {
    margin: '3rem 0'
  },
  paper: {
    display: 'flex',
    padding: '1rem',
    marginBottom: '1rem',
    alignItems: 'center',
    '& p': {
      width: '20%',
      fontSize: '0.8rem'
    }
  },
  buttons: {
    display: 'flex',
    width: '30%',
    justifyContent: 'right',
    alignItems: 'right',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  }
}));

const parseStatus = status => {
  const statuses = {
    NEW: 'NOVO',
    PENDING: 'PENDENTE',
    APPROVED: 'APROVADO',
    BROADCASTING: 'DIVULGANDO',
    RUNNING: 'RODANDO',
    WAITING_PAYMENT: 'AGUARDANDO PAGAMENTO',
    FINISHED: 'FINALIZADO',
    DEFAULT: 'SEM STATUS'
  };

  return statuses[status] ?? statuses.DEFAULT;
};

const Campaigns = props => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [campaignId, setCampaignId] = useState('');

  const [open, setOpen] = useState(false);
  const [openToggleStatus, setOpenToggleStatus] = useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState({});

  const {
    campaignCreateLoading,
    campaignUpdateLoading,
    campaignUpdateStatusLoading,
    campaigns
  } = useSelector(state => state.socialcommerce);

  const { brandSelected } = useSelector(state => state.filter);

  useEffect(() => {
    if (!brandSelected?.id) return;
    dispatch(getCampaignsRequest());
  }, [dispatch, brandSelected]);

  useEffect(() => {
    if (!campaignCreateLoading) {
      dispatch(getCampaignsRequest());
    }
  }, [dispatch, campaignCreateLoading]);

  useEffect(() => {
    if (!campaignUpdateLoading) {
      dispatch(getCampaignsRequest());
    }
  }, [dispatch, campaignUpdateLoading]);

  useEffect(() => {
    if (!campaignUpdateStatusLoading) {
      dispatch(getCampaignsRequest());
    }
  }, [dispatch, campaignUpdateStatusLoading]);

  function handleOpenNewCampaignModal({ campaignId }) {
    dispatch(getCampaignRequest({ campaignId }));
    setOpen(true);
  }

  function handleOpenToggleCampaignStatus({ campaignId }) {
    const currentCampaign = campaigns.find(campaign => campaign.id === campaignId);
    setSelectedCampaign(currentCampaign);
    setOpenToggleStatus(true);
  }

  function getSituation(campaign) {
    const hasApprovedInfluencer = campaign?.contracts?.some(
      contract => contract.status === 'APPROVED'
    );
    const hasPendentInfluencer = campaign?.contracts?.length;

    return hasApprovedInfluencer
      ? 'CONTRATO ATIVO'
      : hasPendentInfluencer
      ? 'EXISTEM PENDÊNCIAS'
      : 'NÃO EXISTEM PENDÊNCIAS';
  }

  return (
    <div className={classes.root}>
        <div className={classes.pageHeader}>
          <Typography variant="body1" className={classes.title}>
            Campanhas 
          </Typography>
          <SelectEdition />
        </div>

        <FullScreenDialog
          setCampaignId={setCampaignId}
          open={open}
          setOpen={setOpen}
          campaignId={campaignId}
        />
        <ConfirmActionUpdateStatusDialog
          open={openToggleStatus}
          setOpen={setOpenToggleStatus}
          approved={['APPROVED', 'NEW'].includes(selectedCampaign?.status)}
          campaignId={selectedCampaign?.id}
        />

        <Box className={classes.box}>
          <Box className={classes.paper}>
            <Typography>Título</Typography>
            <Typography>Status</Typography>
            <Typography>Situação</Typography>
            <Typography>Ação</Typography>
          </Box>

          {campaigns
            ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map(campaign => (
              <Paper key={campaign.id} className={classes.paper}>
                <Typography>{campaign.title}</Typography>
                <Typography>{parseStatus(campaign.status)}</Typography>
                <Typography>{getSituation(campaign)}</Typography>
                <Box className={classes.buttons}>
                  {['APPROVED', 'NEW', 'BROADCASTING'].includes(campaign.status) && (
                    <Button
                      className={classes.button}
                      onClick={() => handleOpenToggleCampaignStatus({ campaignId: campaign.id })}
                      variant="outlined"
                      color="secondary"
                    >
                      {campaign.status === 'BROADCASTING' ? 'PARAR' : 'DIVULGAR'}
                    </Button>
                  )}
                  {['WAITING_PAYMENT'].includes(campaign.status) && (
                    <Button
                      className={classes.button}
                      onClick={() => window.open(campaign?.payment?.url)}
                      variant="outlined"
                      color="default"
                      disabled={!campaign?.payment?.url}
                    >
                      {campaign?.payment?.url ? 'PAGAR BOLETO' : 'CONTATE SUPORTE'}
                    </Button>
                  )}
                  <Button
                    className={classes.button}
                    onClick={() => handleOpenNewCampaignModal({ campaignId: campaign.id })}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Ver mais
                  </Button>
                </Box>
              </Paper>
            ))}
        </Box>
      </div>
  );
};

export default Campaigns;
