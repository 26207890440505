// @flow
export const SIGN_IN = '[SIGN_IN] Sign-in';
export const SIGN_OUT = '[SIGN_OUT] Sign-out';
export const SIGN_IN_SUCCESS = '[SIGN_IN] Sign-in Success';
export const SIGN_IN_FAIL = '[SIGN_IN] Sign-in Fail';
export const SIGN_IN_FROM_OUT = '[SIGN_IN] Sign-in from out';

type SignIn = { type: typeof SIGN_IN, email: string, password: string };
type SignOut = { type: typeof SIGN_OUT };
type SignInSuccess = { type: typeof SIGN_IN_SUCCESS, payload: any };
type SignInFail = { type: typeof SIGN_IN_FAIL, payload: any };
type SignInFromOut = { type: typeof SIGN_IN_FROM_OUT };

export type AuthAction = SignIn | SignInSuccess | SignInFail | SignOut | SignInFromOut;
