// @flow
import * as fromTypes from '../types/auth';

export const initialState = {
  loading: false,
  user: {},
  token: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromTypes.SIGN_IN:
      return {
        ...state,
        loading: true
      };
    case fromTypes.SIGN_IN_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
