import React, { useEffect } from 'react';
import algoliasearch from 'algoliasearch';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import Rheostat from 'rheostat';
import 'rheostat/css/rheostat.css';

import {
  InstantSearch,
  SearchBox,
  MenuSelect,
  Pagination,
  connectStats,
  connectHits,
  connectRange,
  HitsPerPage
} from 'react-instantsearch-dom';

import PartnerCard from './Card';

const useStyles = makeStyles(theme => ({
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& ul': {
      display: 'flex',
      width: '100%',

      '& li': {
        padding: '0.5rem',
        color: '#2b2ed3',
        margin: '0 0.5rem',
        border: '1px solid #2b2ed3',
        cursor: 'pointer',
        borderRadius: 6
      }
    }
  },
  content: {
    margin: '2rem 0',
    '& ul': {
      padding: 0
    },
    '& li': {
      listStyle: 'none'
    }
  },
  searchBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '1.5rem',
    '& input': {
      width: 300,
      borderRadius: 18,
      border: '1px solid #ccc',
      padding: '0.5rem'
    },
    '& select': {
      width: 300,
      borderRadius: 18,
      border: '1px solid #ccc',
      padding: '0.5rem',
      marginBottom: '0.5rem'
    },
    '& button': {
      display: 'none'
    }
  },
  slider: {
    margin: '1rem',
    width: '67.5%',
    '& p': {
      margin: 0
    }
  }
}));

const searchClient = algoliasearch('J9REWNDIJ0', 'd8a3d640ec25d358ebccbefefceaf856', {
  // Caches responses from Algolia
  responsesCache: createInMemoryCache(), // or createNullCache()

  // Caches Promises with the same request payload
  requestsCache: createInMemoryCache({ serializable: false }) // or createNullCache()
});

const Sidebar = () => {
  return <Box />;
};

const Stats = ({ nbHits }) => {
  return (
    <>
      <Typography>{`Total de resultados: ${nbHits}`}</Typography>
      <HitsPerPage defaultRefinement={30} items={[{ value: 30, label: 'Mostrar 30 parceiras' }]} />
    </>
  );
};

const Hits = ({ hits }) => {
  return (
    <Box>
      {hits.map(hit => (
        <PartnerCard hit={hit} />
      ))}
    </Box>
  );
};

const RangeSlider = ({ currentRefinement, min, max, refine, canRefine }) => {
  const [stateMin, setStateMin] = React.useState(min);
  const [stateMax, setStateMax] = React.useState(max);

  useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
  }, [currentRefinement.min, currentRefinement.max, canRefine]);

  if (min === max) {
    return null;
  }

  const onChange = ({ values: [min, max] }) => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min, max });
    }
  };

  const onValuesUpdated = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
  };

  return (
    <Rheostat
      min={min}
      max={max}
      values={[currentRefinement.min, currentRefinement.max]}
      onChange={onChange}
      onValuesUpdated={onValuesUpdated}
    >
      <p>
        Min: 
{' '}
{stateMin} - Max:
        {stateMax}
      </p>
    </Rheostat>
  );
};

const Content = () => {
  const classes = useStyles();

  const CustomStats = connectStats(Stats);
  const CustomHits = connectHits(Hits);

  return (
    <Box className={classes.content}>
      <Box>
        <CustomStats />
      </Box>
      <CustomHits />
      <Box className={classes.pagination}>
        <Pagination showLast />
      </Box>
    </Box>
  );
};


function Algolia() {
  const classes = useStyles();

  const CustomRangeSlider = connectRange(RangeSlider);

  function getStateFilters({
    menu,
    range,
    query
  }) {
    let filters = ''
    let numericFilters = []

    if (menu?.calca) {
      filters = `calca:"${menu.calca}"`
    }

    if (menu?.calcado) {
      filters = `${filters} ${filters.length ? 'AND' : ''} calcado:"${menu.calcado}"`
    }

    if (menu?.camiseta) {
      filters = `${filters} ${filters.length ? 'AND' : ''} camiseta:"${menu.camiseta}"`
    }

    if (range?.idade?.min && range?.idade?.max) {
      numericFilters = [`idade: ${range.idade.min} TO ${range.idade.max}`]
    }

    return {
      filters,
      numericFilters,
      query
    }
  }

  async function onChangeStates(event) {
    const filters = getStateFilters(event)
    localStorage.setItem('b2b:filter', JSON.stringify(filters))
  }

  return (
    <InstantSearch
      indexName="PROD_UAUTEAM"
      searchClient={searchClient}
      onSearchStateChange={e => onChangeStates(e)}
    >
      <Box className={classes.searchBox}>
        <SearchBox translations={{ placeholder: 'Buscar influencers' }} />
        <MenuSelect
          attribute="camiseta"
          translations={{
            seeAllOption: 'Camiseta'
          }}
        />
        <MenuSelect
          attribute="calcado"
          translations={{
            seeAllOption: 'Calçado'
          }}
        />
        <MenuSelect
          attribute="calca"
          translations={{
            seeAllOption: 'Calça'
          }}
        />
      </Box>

      <Box className={classes.slider}>
        <CustomRangeSlider attribute="idade" min={18} max={65} />
      </Box>

      <Box>
        <Sidebar />
        <Content />
      </Box>
    </InstantSearch>
  );
}

export default Algolia;
