import styled from 'styled-components';
import colors from '../../styles/colors';
import font from '../../styles/fontsSizes';
import sizes from '../../styles/sizes';

export const Container = styled.div`
  background: ${colors.DARKPRIMARY};
  height: 100%;
  width: 100%;
  z-index: 0;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    width: 5%;
    left: 27%;
    top: 8%;

    @media (max-width: ${sizes.TABLET}) {
      &:first-child {
        display: none;
      }
    }
  }
`;

export const Form = styled.div`
  background: ${colors.WHITE};
  border-radius: 10px;
  width: 25%;
  height: 60%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${sizes.TABLET}) {
    background: transparent;
    width: 100%;

    h1 {
      color: ${colors.WHITE};
      text-align: center;
      width: 60%;
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 20%;
    width: 20%;
    z-index: -1;
    opacity: 0.5;

    @media (max-width: ${sizes.TABLET}) {
      width: 70%;
      left: -35%;
      top: 70%;

      :last-child {
        top: -8%;
        left: 75%;
      }
    }
  }


  h1 {
    color: ${colors.SECONDARY};
    font-size: ${font.EXTRA_BIG};
    font-weight: 900;
    margin-bottom: 10%;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      display: flex;
      width: 70%;
      background: #f4e4c9;
      border-radius: 10px;
      align-items: center;
      padding: 10px 15px;
      margin-bottom: 5%;

      @media (max-width: ${sizes.TABLET}) {
        border-radius: 20px;
      }

      svg {
        margin-right: 5%;
      }

      input {
        width: 100%;
        border: 0;
        background: #f4e4c9;
        font-size: ${font.TOO_SMALL};
      }
    }

    p {
      color: ${colors.DARKGRAY};
      font-size: ${font.TOO_SMALL};
      margin-top: 10%;
      text-align: center;

      a {
        font-weight: bold;
      }

      @media (max-width: ${sizes.TABLET}) {
        color: ${colors.WHITE};
      }

    }

    button {
      background: ${colors.DARKPRIMARY};
      border-radius: 12px;
      color: ${colors.WHITE};
      font-weight: 900;
      font-size: ${font.SMALL};
      border: 0;
      width: 50%;
      padding: 4%;
      margin-top: 5%;

      @media (max-width: ${sizes.TABLET}) {
        background: ${colors.SECONDARY};
        width: 30%;
        border-radius: 20px;
        padding: 3%;
      }
    }
  }
`;

export const Welcome = styled.div`
  @media (max-width: ${sizes.TABLET}) {
    display: none;
  }

  width: 22%;

  aside {
    position: relative;
    z-index: -2;
    height: 80%;
    margin-left: -20%;
    padding: 25%;
    border-radius: 10px;

    background: ${colors.SECONDARY};

    img {
      position: absolute;
      top: 32%;
      left: 25%;
      width: 70%;
      z-index: 0;
      opacity: 0.7;
    }

    h1 {
      font-size: ${font.HUGE};
      font-weight: 900;
      position: relative;
      z-index: 0;

      color: ${colors.DARKPRIMARY};
    }

    p {
      margin-top: 5%;
      font-size: ${font.SMALL};
      color: ${colors.WHITE};

      position: relative;
      z-index: 0;
    }
  }
`;
