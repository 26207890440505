import React, { useEffect, useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Typography, CircularProgress } from '@material-ui/core';

import { fetchChartBrand } from '../../store/actions';
import { white, gray01, darkgray02 } from '../../assets/variables';
import ChartList from './sections/Chart';

import './index.css';
import SelectEdition from '../../components/SelectEdition/SelectEdition';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  loader: {
    margin: '0 auto',
    marginTop: 'calc(20% - 2.5rem)',
    color: darkgray02,
    display: 'block'
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    width: '60%',
    fontWeight: 'bold',
    color: darkgray02,
    fontSize: theme.spacing(2.3)
  },
  subtitle: {
    marginTop: '2rem',
    fontWeight: 'bold',
    color: darkgray02
  },
  filterContainer: {
    display: 'flex',
    width: '40%'
  },
  datePicker: {
    margin: '0 0 0 0.6rem',
    '&:first-child': {
      margin: 0
    }
  },
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
    background: gray01,
    border: '1px solid #E7E6E7',
    borderRadius: '4px',
    color: darkgray02,
    '& span': {
      padding: '0 0.7rem'
    },
    '& button': {
      background: white,
      border: 0,
      padding: '0.7rem',
      cursor: 'pointer',
      '&:hover': {
        background: gray01
      }
    }
  },
  borderButton: {
    borderLeft: '1px solid #E7E6E7 !important',
    borderRight: '1px solid #E7E6E7 !important'
  },
  icon: {
    fontSize: '1.4rem',
    paddingLeft: '0.7rem'
  },
  tabs: {
    marginTop: '2rem',
    '& button': {
      textTransform: 'none'
    }
  },
  tab: {
    borderColor: '#7159c1',
    borderWidth: 1
  },
  formControl: {
    margin: '0 0.5rem',
    width: '100%'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span" variant="body1">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const BrandData = props => {
  const classes = useStyles();

  const { brandSelected, onFetchChartBrand, chartBrand, loading } = props;

  const [value, setValue] = useState(0);

  const { startEdition, endEdition } = useSelector(state => state.filter);

  const updateInfo = useCallback(() => {
    if (brandSelected.id) {
      onFetchChartBrand(brandSelected.id, startEdition, endEdition);
    }
  }, [onFetchChartBrand, brandSelected, startEdition, endEdition]);

  useEffect(() => {
    if (brandSelected.id) {
      updateInfo();
    }
  }, [brandSelected, updateInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calcTotalVotes = ({ data }) => {
    return data?.reduce((accumulator, currentValue) => accumulator + currentValue.numVotes, 0);
  };

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <Typography variant="body1" className={classes.title}>
          Dados
        </Typography>
        <SelectEdition />
      </div>
      {loading ? (
        <CircularProgress size="5rem" className={classes.loader} />
      ) : (
        <div>
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="Questionarios Marca"
          >
            {chartBrand.map((item, index) => (
              <Tab label={item.title} {...a11yProps(index)} key={`tab-${item.id}`} />
            ))}
          </Tabs>
          {chartBrand.map((item, index) => (
            <TabPanel value={value} index={index} key={`tabPanel-${item.id}`}>
              <ChartList chart={chartBrand[index]} totalVotes={calcTotalVotes(chartBrand[index])} />
            </TabPanel>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { brandSelected } = state.filter;
  const { loading, chartBrand } = state.chart;
  return { brandSelected, chartBrand, loading };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchChartBrand: (brandId, startEdition, endEdition) =>
      dispatch(fetchChartBrand(brandId, startEdition, endEdition))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(BrandData);
