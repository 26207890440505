// @flow
import { User } from '../../models';

export const USER_DETAILS = '[USER] User details';
export const USER_DETAILS_SUCCESS = '[USER] User details Success';
export const USER_DETAILS_FAIL = '[USER] User details Fail';

export const UPDATE_USER = '[USER] Update user';
export const UPDATE_USER_SUCCESS = '[USER] Update user Success';
export const UPDATE_USER_FAIL = '[USER] Update user Fail';

export const UPDATE_PASSWORD = '[USER] Update password';
export const UPDATE_PASSWORD_SUCCESS = '[USER] Update password Success';
export const UPDATE_PASSWORD_FAIL = '[USER] Update password Fail';

export const FETCH_USER_LIST = '[USER] Fetch user list';
export const FETCH_USER_LIST_SUCCESS = '[USER] Fetch user list Success';
export const FETCH_USER_LIST_FAIL = '[USER] Fetch user list Fail';

export const ADD_USER = '[USER] Add user';
export const ADD_USER_SUCCESS = '[USER] Add user Success';
export const ADD_USER_FAIL = '[USER] Add user Fail';

type UserDetails = { type: typeof USER_DETAILS, id: string };
type UserDetailsSuccess = { type: typeof USER_DETAILS_SUCCESS, payload: any };
type UserDetailsFail = { type: typeof USER_DETAILS_FAIL, payload: any };

type UpdateUser = {
  type: typeof UPDATE_USER,
  user: User
};
type UpdateUserSuccess = { type: typeof UPDATE_USER_SUCCESS, payload: any };
type UpdateUserFail = { type: typeof UPDATE_USER_FAIL, payload: any };

type UserPassword = {
  type: typeof UPDATE_PASSWORD,
  id: string,
  password: string
};
type UserPasswordSuccess = { type: typeof UPDATE_PASSWORD_SUCCESS, payload: any };
type UserPasswordFail = { type: typeof UPDATE_PASSWORD_FAIL, payload: any };

type FetchUserList = {
  type: typeof FETCH_USER_LIST,
  sort: string,
  order: string,
  page: number,
  size: number
};
type FetchUserListSuccess = { type: typeof FETCH_USER_LIST_SUCCESS, payload: any };
type FetchUserListFail = { type: typeof FETCH_USER_LIST_FAIL, payload: any };

type AddUser = {
  type: typeof ADD_USER,
  user: User
};
type AddUserSuccess = { type: typeof ADD_USER_SUCCESS, payload: any };
type AddUserFail = { type: typeof ADD_USER_FAIL, payload: any };

export type UserAction =
  | UserDetails
  | UserDetailsSuccess
  | UserDetailsFail
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail
  | UserPassword
  | UserPasswordSuccess
  | UserPasswordFail
  | FetchUserList
  | FetchUserListSuccess
  | FetchUserListFail
  | AddUser
  | AddUserSuccess
  | AddUserFail;
