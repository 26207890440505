// @flow
import moment from 'moment';

const DEFAULT_INIT_DATE = moment().subtract(1, 'months');
const DEFAULT_END_DATE = moment();
const parseDate = (date, defaultDate) =>
  date ? moment(date).format('YYYY-MM-DD') : moment(defaultDate).format('YYYY-MM-DD');

class Filter {
  productId: string;

  brandId: string;

  state: string;

  startDate: Date;

  endDate: Date;

  constructor(data: any = {}) {
    this.productId = data.product || data.productId;
    this.brandId = data.brandId;
    this.state = data.state;
    this.startDate = parseDate(data.startDate, DEFAULT_INIT_DATE);
    this.endDate = parseDate(data.endDate, DEFAULT_END_DATE);
  }
}

export default Filter;
