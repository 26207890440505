import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { useDispatch } from 'react-redux'

import { updateCampaignStatusRequest } from '../../store/actions/socialcommerce'

export default function AlertDialog({
  open,
  setOpen,
  approved,
  campaignId
}) {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.socialcommerce.campaignUpdateStatusLoading);

  const [clicked, setClicked] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  async function handleToggleStatus() {
    dispatch(updateCampaignStatusRequest({
      id: campaignId,
      status: approved ? 'BROADCASTING' : 'APPROVED'
    }));

    setClicked(true)
  }

  useEffect(() => {
    if (!isLoading && clicked) {
      setOpen(false)
    }
  }, [isLoading, clicked, setOpen])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Alteraração de Campanha
          <Tooltip title={`${approved
              ? `Ao retroceder para aprovada, sua campanha não será mais divulgada.`
              : `Ao divulgar uma campanha, ela passará a ser mostrada para as influencers.`
            }`}
          >
            <IconButton aria-label="delete">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente {approved ? 'DIVULGAR essa campanha?' : 'voltar a campanha para APROVADA?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isLoading
            ? <CircularProgress size={20} />
            : <>
                <Button onClick={handleToggleStatus} color="primary">
                  Sim
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Não
                </Button>
              </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
