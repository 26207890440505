import {put, select, takeLatest} from 'redux-saga/effects';
import * as fromTypes from '../types/socialcommerce';
import * as fromActions from '../actions';
import * as Sentry from "@sentry/react";

import { socialCommerceApi } from '../../services/api';
import { getHeaders } from '../services/auth';

import { toast } from 'react-toastify';
import {getFilter} from "./filter";

export function* getAll() {
  const filter = yield select(getFilter);

  if (!filter.brandSelected?.id) return;

  try {
    const response = yield socialCommerceApi.get(
      `/campaigns/all/${filter.brandSelected?.id}`,
    );

    yield put(fromActions.getCampaignsSuccess(response.data));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* getByBrandId({ payload }) {
  try {
    const { campaignId } = payload;

    const response = yield socialCommerceApi.get(
      `/campaigns/${campaignId}`,
    );

    yield put(fromActions.getCampaignSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao recuperar a campanha")
    Sentry.captureException(error);
  }
}

export function* updateCampaign({ payload }) {
  try {
    const { campaign, formData } = payload;

    const {
      id,
      title,
      description,
      segment,
      product_type,
      product,
      partner_minimum_age,
      start_date,
      end_date,
      status,
      tracking_url,
      product_price,
      brand_name,
      allowed_partners,
      max_contracts,
      restricted
    } = campaign;

    const response = yield socialCommerceApi.put(
      `/campaigns`,
      {
        id,
        title,
        description,
        segment,
        product_type,
        product,
        partner_minimum_age,
        start_date,
        end_date,
        status,
        tracking_url,
        product_price,
        brand_name,
        allowed_partners,
        max_contracts,
        restricted
      },
      {
        headers: getHeaders()
      }
    );

    if (formData) {
      yield put(fromActions.createCampaignSuccess({
        campaign_id: response.data.id,
        formData
      }));
    }

    localStorage.removeItem('b2b:ids')
    localStorage.removeItem('b2b:campaign')
    toast.success('Campanha alterada com sucesso.')

  } catch (error) {
    toast.error("Falha ao alterar a campanha")
    Sentry.captureException(error);
  }
}

export function* createCampaign({ payload }) {
  try {
    const { campaign, formData } = payload;

    const filter = yield select(getFilter);

    const response = yield socialCommerceApi.post(
      `/campaigns`,
      {
        ...campaign,
        brand_id: filter.brandSelected.id,
        brand_name: filter.brandSelected.name
      },
      {
        headers: getHeaders()
      }
    );

    yield put(fromActions.createCampaignSuccess({
      campaign_id: response.data.id,
      formData
    }));

    localStorage.removeItem('b2b:ids')
    localStorage.removeItem('b2b:campaign')
    toast.success('Campanha criada com sucesso.')

  } catch (error) {
    toast.error("Erro ao criar a campanha");
    Sentry.captureException(error);
  }
}

export function* attachCampaignBriefing({ payload }) {
  try {
    const { campaign_id, formData } = payload;

    yield socialCommerceApi.put(
      `/campaign-attachment/${campaign_id}`, formData,
      {
        headers: getHeaders()
      }
    );
  } catch (error) {
    toast.error("Falha ao adicionar o briefing")
  }
}

export function* approveContract({ payload }) {
  try {
    const { contractId, isApproved } = payload;

    const response = yield socialCommerceApi.put(
      `/contract-partner`, {
        id: contractId,
        approved: isApproved
      },
      {
        headers: getHeaders()
      }
    );

    yield put(fromActions.updateContractStatusSuccess(response.data));
    
    toast.success(`Parceira aprovada com sucesso.`)
  } catch (error) {
    const message = error?.response?.data?.message ?? "Falha ao aprovar o contrato"
    toast.error(message)
    yield put(fromActions.updateContractStatusError());
  }
}

export function* updateContractStatus({ payload }) {
  try {
    const { id, status } = payload;

    const response = yield socialCommerceApi.put(
      `/contract-status`, {
        id,
        status,
      },
      {
        headers: getHeaders()
      }
    );

    toast.success('Contrato atualizado com sucesso.')

    yield put(fromActions.updateContractStatusSuccess(response.data));
  } catch (error) {
    toast.error("Falha ao atualizar o contrato")
  }
}

export function* updateCampaignContractStatus({ payload }) {
  try {
    const { id, status } = payload;

    const response = yield socialCommerceApi.put(
      `/campaign-status`, {
        id,
        status,
      },
      {
        headers: getHeaders()
      }
    );

    toast.success('Campanha atualizada com sucesso.')

    yield put(fromActions.updateCampaignStatusSuccess(response.data));
  } catch (error) {
    toast.error("Falha ao atualizar o status da campanha");
  }
}

export function* watchSocialCommerce() {
  yield takeLatest(fromTypes.GET_CAMPAIGNS_REQUEST, getAll);
  yield takeLatest(fromTypes.GET_CAMPAIGN_REQUEST, getByBrandId);
  yield takeLatest(fromTypes.UPDATE_CAMPAIGN_REQUEST, updateCampaign);
  yield takeLatest(fromTypes.CREATE_CAMPAIGN_REQUEST, createCampaign);
  yield takeLatest(fromTypes.CREATE_CAMPAIGN_SUCCESS, attachCampaignBriefing);
  yield takeLatest(fromTypes.APPROVE_PARTNER_CONTRACT_REQUEST, approveContract);
  yield takeLatest(fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_REQUEST, updateContractStatus);
  yield takeLatest(fromTypes.UPDATE_CAMPAIGN_STATUS_REQUEST, updateCampaignContractStatus);

}
