export default {
  WHITE: '#FFFFFF',
  CREAMWHITE: '#EBE2D7',

  GRAY: '#00000029',
  LIGHTGRAY: '#F0F4DF',
  MEDIUMGRAY: '#707070',
  DARKGRAY: '#545454',
  SLATEGRAY: '#AFAEAE',

  PRIMARY: '#092058',
  MEDIUMPRIMARY: '#051A4B',
  DARKPRIMARY: '#000E30',

  SECONDARY: '#D59C36',
  CREAMSECONDARY: '#E2B880',

  BROWN: '#635650',
  VIOLET: '#A3A9C4',

  FONTS: {
    BLUE: '#000E30',
    BLACK: '#635650'
  },

  heatMap: {
    blueOne: '#9fa8da',
    blueTwo: '#6f79a8',
    blueThree: '#7986cb',
    blueFour: '#49599a',
    blueFive: '#5c6bc0',
    blueSix: '#3f51b5',
    blueSeven: '#3949ab',
    blueEight: '#303f9f',
    blueNine: '#283593',
    blueTen: '#1a237e',
    blueEleven: '#001064',
    blueTwelve: '#000051',
  }
};
