import { Button, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Node, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { renderSelectField, renderTextField } from '../../components/wrapper';
import { User } from '../../models';
import { fetchBrandList, updateUser, userDetails } from '../../store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      width: '70%',
      margin: 'auto',
      maxWidth: '100rem'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: '100%'
  },
  header: {
    height: '64px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: '24px',
    borderBottom: '1px solid #DFE3E8',
    paddingRight: '24px',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: theme.spacing(1),
    height: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
}));

type WrapperProps = {
  children: Node
};
const Wrapper = ({ children }: WrapperProps) => (
  <Grid item lg={6} xs={12}>
    {children}
  </Grid>
);

const validate = values => {
  const errors = {};
  const requiredFields = ['firstName', 'lastName', 'email', 'username'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Endereço de email invalido';
  }
  return errors;
};

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  dispatch: Function,
  match: Object,
  onFetchUserDetails: Function,
  onUpdateUser: Function,
  onFetchBrandList: Function,
  brandList: Array<Object>
};
const UserDetails = (props: Props) => {
  const classes = useStyles();
  const {
    handleSubmit,
    submitting,
    dispatch,
    match,
    onFetchUserDetails,
    onUpdateUser,
    onFetchBrandList,
    brandList,
  } = props;

  useEffect(() => {
    onFetchUserDetails(match.params.id);
    onFetchBrandList();
  }, [onFetchUserDetails, match, onFetchBrandList]);

  const onSubmit = (user: User) => onUpdateUser(new User({ ...user, id: match.params.id }));

  const handleOnChangePassword = () => dispatch(push(`/users/changePassword/${match.params.id}`));

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Detalhes de Usuario
        </Typography>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
        <Grid container spacing={1}>
          <Wrapper>
            <Field
              name="email"
              type="text"
              component={renderTextField}
              label="Email"
              className={classes.textField}
              disabled
            />
          </Wrapper>

          <Wrapper>
            <Field
              name="type"
              component={renderSelectField}
              label="Perfil de usuario"
              className={classes.textField}
              disabled
            >
              <MenuItem value="ROLE_MERCHANT">Cliente</MenuItem>
              <MenuItem value="ROLE_ADMIN">Administrador</MenuItem>
            </Field>
          </Wrapper>

          <Wrapper>
            <TextField
              name="brandIds"
              label="Marcas"
              className={classes.textField}
              value={brandList.map(brand => brand.name).join(", ")}
              variant="outlined"
              disabled 
            />
          </Wrapper>
        </Grid>

        <div className={classes.footer}>
          <Button
            color="primary"
            disabled={submitting}
            variant="text"
            onClick={handleOnChangePassword}
          >
            Trocar Senha
          </Button>
          {/* <Button color="primary" type="submit" disabled={submitting} variant="contained">
            Salvar
          </Button> */}
        </div>
      </form>
    </Paper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading, user } = state.user;
  const { brandList } = state.filter;

  const brandIds = state.form?.userDetails?.initial?.data?.brandIds || [];

  const filteredBrandList = brandList.filter(brand => brandIds.includes(brand.id))

  return { loading, user, match: ownProps.match, brandList: filteredBrandList };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUserDetails: id => dispatch(userDetails(id)),
    onUpdateUser: (user: User) => dispatch(updateUser(user)),
    onFetchBrandList: () => dispatch(fetchBrandList())
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'userDetails',
    validate,
  })
)(UserDetails);
