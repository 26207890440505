import * as fromTypes from '../types/socialcommerce';

export const getCampaignsRequest = () => ({
  type: fromTypes.GET_CAMPAIGNS_REQUEST
});

export const getCampaignRequest = ({ campaignId }) => ({
  type: fromTypes.GET_CAMPAIGN_REQUEST,
  payload: { campaignId }
});

export const getCampaignsSuccess = payload => ({
  type: fromTypes.GET_CAMPAIGNS_SUCCESS,
  payload
});

export const getCampaignSuccess = payload => ({
  type: fromTypes.GET_CAMPAIGN_SUCCESS,
  payload
});

export const createCampaignRequest = ({ campaign, formData, brand_name }) => ({
  type: fromTypes.CREATE_CAMPAIGN_REQUEST,
  payload: { campaign, formData, brand_name }
});

export const createCampaignSuccess = ({ campaign_id, formData }) => ({
  type: fromTypes.CREATE_CAMPAIGN_SUCCESS,
  payload: { campaign_id, formData }
});

export const updateCampaignRequest = ({ campaign, formData }) => ({
  type: fromTypes.UPDATE_CAMPAIGN_REQUEST,
  payload: { campaign, formData }
});

export const updateCampaignSuccess = payload => ({
  type: fromTypes.UPDATE_CAMPAIGN_SUCCESS,
  payload
});

export const updateCampaignStatusRequest = ({ id, status }) => ({
  type: fromTypes.UPDATE_CAMPAIGN_STATUS_REQUEST,
  payload: { id, status }
});

export const updateCampaignStatusSuccess = payload => ({
  type: fromTypes.UPDATE_CAMPAIGN_STATUS_SUCCESS,
  payload
});

export const approvePartnerRequest = ({ contractId, isApproved }) => ({
  type: fromTypes.APPROVE_PARTNER_CONTRACT_REQUEST,
  payload: { contractId, isApproved }
});

export const approvePartnerSuccess = () => ({
  type: fromTypes.APPROVE_PARTNER_CONTRACT_SUCCESS
});

export const updateContractStatusRequest = ({ id, status }) => ({
  type: fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_REQUEST,
  payload: { id, status }
});

export const updateContractStatusSuccess = payload => ({
  type: fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_SUCCESS,
  payload
});

export const updateContractStatusError = payload => ({
  type: fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_ERROR,
});

export const addPartnerToCampaign = ({ partnerId }) => ({
  type: fromTypes.ADD_PARTNER_TO_CAMPAIGN,
  payload: { partnerId }
});

export const addAllPartnerToCampaign = ({ partnerIds }) => ({
  type: fromTypes.ADD_ALL_PARTNERS_TO_CAMPAIGN,
  payload: { partnerIds }
});

export const removePartnerFromCampaign = ({ partnerId }) => ({
  type: fromTypes.REMOVE_PARTNER_FROM_CAMPAIGN,
  payload: { partnerId }
});
