import * as fromTypes from '../types/auth';

export const signIn = (email, password) => ({
  type: fromTypes.SIGN_IN,
  email,
  password
});

export const signOut = () => ({
  type: fromTypes.SIGN_OUT
});

export const signInSuccess = () => ({
  type: fromTypes.SIGN_IN_SUCCESS
});

export const signInFail = payload => ({
  type: fromTypes.SIGN_IN_FAIL,
  payload
});

export const me = () => ({
  type: fromTypes.SIGN_IN_FROM_OUT
});
