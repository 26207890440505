import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { editionsRequest, saveFinalEdition, saveInitialEdition } from '../../store/actions';
import { convertEditions } from '../../utils/convertEditions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '40%'
  },
  formControl: {
    margin: '0 0.5rem',
    width: '100%'
  }
}));

function SelectEdition() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { startEdition, endEdition } = useSelector(state => state.filter);
  const { editions } = useSelector(state => state.edition);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(editionsRequest());
  }, [dispatch])

  useEffect(() => {
    if (editions?.length > 0) {
      const editionsMapped = convertEditions(editions)

      setOptions(editionsMapped)

      !startEdition && dispatch(saveInitialEdition({ startEdition: editionsMapped[editionsMapped.length - 1].id }));
      !endEdition && dispatch(saveFinalEdition({ endEdition: editionsMapped[0].id }));
    }
  }, [dispatch, editions, startEdition, endEdition]);

  const handleEditionStartChange = useCallback(startEdition => {
    dispatch(saveInitialEdition({ startEdition }));
  }, [dispatch]);

  const handleEditionEndChange = useCallback(endEdition => {
    dispatch(saveFinalEdition({ endEdition }));
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
      <InputLabel id="start-edition">Edição Inicial</InputLabel>
        <Select
          labelId="start-edition"
          id="start-edition"
          value={startEdition}
          onChange={e => handleEditionStartChange(e.target.value)}
          fullWidth
        >
          {options.map(item => (
            <MenuItem
              key={item.id}
              disabled={parseInt(endEdition) < parseInt(item.id) }
              value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="end-edition">Edição Final</InputLabel>
        <Select
          labelId="end-edition"
          id="end-edition"
          value={endEdition}
          onChange={e => handleEditionEndChange(e.target.value)}
        >
          {options.map(item => (
            <MenuItem
              key={item.id}
              disabled={parseInt(startEdition) > parseInt(item.id) }
              value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectEdition;
