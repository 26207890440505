import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ConfirmActionDialog from './ConfirmActionDialog';

import { useSelector } from 'react-redux';
import { useState } from 'react';

import ShowAddress from './ShowAddress';

import {
  updateContractStatusRequest
} from '../../store/actions/socialcommerce';
import BackdropView from '../../components/Backdrop';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0.5rem',
    alignItems: 'center',
    margin: '1rem 0'
  }
}));

const parseStatus = status => {
  const statuses = {
    NEW: 'NOVO',
    PENDING: 'PENDENTE',
    APPROVED: 'APROVADO',
    BROADCASTING: 'DIVULGANDO',
    RUNNING: 'RODANDO',
    WAITING_PAYMENT: 'AGUARDANDO PAGAMENTO',
    FINISHED: 'FINALIZADO',
    PRODUCT_IN_TRANSIT: 'PRODUTO EM TRANSITO',
    PRODUCT_RECEIVED: 'PRODUTO RECEBIDO',
    CAMPAIGN_FINISHED: 'CAMPANHA FINALIZADA',
    DEFAULT: status
  };

  return statuses[status] ?? statuses.DEFAULT;
};

export default function StepThree({ campaignObj }) {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState({ open: false, action: null });
  const [openAddress, setOpenAddress] = useState(false);

  const [contract, setContract] = useState(null);

  const campaign = useSelector(state => state.socialcommerce.campaign);

  function handleApprove(contract) {
    setContract(contract);
    setOpenDialog({ open: true, action: 'approve' });
  }

  function handleReprove(contract) {
    setContract(contract);
    setOpenDialog({ open: true, action: 'reject' });
  }

  function handleUpdateToPending(contract) {
    setContract(contract);
    setOpenDialog({ open: true, action: 'revoke' });
  }

  function getInstaUrl(partner_instagram) {
    return partner_instagram.includes('instagram.com')
      ? partner_instagram
      : `https://instagram.com.br/${partner_instagram}`;
  }

  const isLoading = useSelector(state => state.socialcommerce.isLoading);

  return (
    <BackdropView isOpen={isLoading}>
      <Box>
        <Divider
          style={{
            margin: '1rem 0'
          }}
        />

        {campaignObj?.contracts?.length ? (
          <Typography className={classes.sectionTitle}>
            {campaignObj?.contracts?.some(contract => contract.status === 'APPROVED')
              ? 'Solicitação aprovada.'
              : 'Solicitações pendentes.'}
            {/* <Tooltip title="Todo contrato tem um limite">
              <IconButton aria-label="delete">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip> */}
          </Typography>
        ) : (
          <Typography className={classes.sectionTitle}>
            Nenhuma solicitação pendente.
          </Typography>
        )}
        {/*
        TODO: Alterar o map para usar lista do React,
        melhorando performance da aplicacao e evitando crashes
        */}
        {campaignObj.contracts?.map(contract => (
          <Paper key={contract.id} className={classes.paper}>
            <Typography>{contract.partner_name}</Typography>
            <a
              href={getInstaUrl(contract.partner_instagram)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver instagram
            </a>
            <Typography>{parseStatus(contract.status)}</Typography>
            {['PENDING', 'REPROVED'].includes(contract.status) &&
              ['BROADCASTING', 'APPROVED'].includes(campaignObj.status) && (
                <Box display="flex" alignItems="center">
                  <Button
                    className={classes.button}
                    onClick={() => handleReprove(contract)}
                    variant="outlined"
                    color="secondary"
                    style={{
                      marginRight: '0.5rem'
                    }}
                    size="small"
                  >
                    Reprovar
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => handleApprove(contract)}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Aprovar
                  </Button>
                </Box>
              )}
              {['APPROVED', 'REJECTED'].includes(contract.status) && <Box>
                <Button
                  className={classes.button}
                  onClick={() => handleUpdateToPending(contract)}
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  Revogar
                </Button>
              </Box>}
          </Paper>
        ))}
      </Box>
      {campaign?.contracts && (
        <ShowAddress
          partner={
            campaign?.contracts.find(contract => contract.status === 'APPROVED')?.partner
          }
          open={openAddress}
          setOpen={setOpenAddress}
        />
      )}
      <ConfirmActionDialog
        open={openDialog}
        setOpen={setOpenDialog}
        contract={contract}
        handleAction={updateContractStatusRequest}
      />
    </BackdropView>
  )
}