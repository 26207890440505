// @flow
import * as fromTypes from '../types/emailMarketing';

export const initialState = {
  loading: false,
  total: 0,
  pageActive: 0,
  emailMarketingList: [],
  saveLoading: false,
  isSaved: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromTypes.FETCH_EMAIL_MARKETING_LIST:
      return {
        ...state,
        loading: true,
        pageActive: action.pageIndex,
        isSaved: false
      };
    case fromTypes.FETCH_EMAIL_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        emailMarketingList: action.payload.data,
        total: action.payload.total
      };
    case fromTypes.FETCH_EMAIL_MARKETING_LIST_FAIL:
      return {
        ...state,
        loading: false,
        emailMarketingList: [],
        total: 0,
        pageActive: 0
      };
    case fromTypes.FETCH_SAVE_EMAIL_MARKETING:
      return {
        ...state,
        saveLoading: true,
        isSaved: false
      };
    case fromTypes.FETCH_SAVE_EMAIL_MARKETING_SUCCESS:
      return {
        ...state,
        saveLoading: false,
        isSaved: true
      };
    case fromTypes.FETCH_SAVE_EMAIL_MARKETING_FAIL:
      return {
        ...state,
        saveLoading: false,
        isSaved: false
      };
    default:
      return state;
  }
};

export default reducer;
