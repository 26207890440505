// @flow

class Chart {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  username: string;

  password: string;

  confirmPassword: string;

  companyId: number;

  userType: string;

  constructor(data: any) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.username = data.username;
    this.password = data.password;
    this.confirmPassword = data.confirmPassword;
    this.companyId = data.companyId;
    this.userType = data.userType;
  }
}

export default Chart;
