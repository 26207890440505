// @flow
export const FETCH_COMPANY_LIST = '[COMPANY] Fetch company list';
export const FETCH_COMPANY_LIST_SUCCESS = '[COMPANY] Fetch company list Success';
export const FETCH_COMPANY_LIST_FAIL = '[COMPANY] Fetch company list Fail';

type FetchCompanyList = { type: typeof FETCH_COMPANY_LIST, id: string };
type FetchCompanyListSuccess = { type: typeof FETCH_COMPANY_LIST_SUCCESS, payload: any };
type FetchCompanyListFail = { type: typeof FETCH_COMPANY_LIST_FAIL, payload: any };

export type CompanyAction = FetchCompanyList | FetchCompanyListSuccess | FetchCompanyListFail;
