import * as fromTypes from '../types/socialcommerce';

export const initialState = {
  campaigns: [],
  campaign: {},
  campaignCreateLoading: false,
  campaignUpdateLoading: false,
  campaignUpdateStatusLoading: false,
  partnerIds: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromTypes.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload
      };
    case fromTypes.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload
      };
    case fromTypes.UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaign: action.payload,
        campaignUpdateLoading: true
      };
    case fromTypes.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        campaignUpdateLoading: false
      };
    case fromTypes.CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaign: action.payload,
        campaignCreateLoading: true
      };
    case fromTypes.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        campaignCreateLoading: false
      };
    case fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_REQUEST:
    case fromTypes.APPROVE_PARTNER_CONTRACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
      case fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_ERROR: 
        return {
          ...state,
          isLoading: false
        }
    case fromTypes.UPDATE_PARTNER_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaign: {
          ...state.campaign,
          contracts: state.campaign.contracts.map(contract =>
            contract.id === action.payload.id ? action.payload : contract
          )
        }
      };

    case fromTypes.UPDATE_CAMPAIGN_STATUS_REQUEST:
      return {
        ...state,
        campaignUpdateStatusLoading: true
      };
    case fromTypes.UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        campaignUpdateStatusLoading: false,
        campaign: action.payload
      };
    case fromTypes.ADD_PARTNER_TO_CAMPAIGN:
      return {
        ...state,
        partnerIds: [
          ...state.partnerIds.filter(partnerId => partnerId !== action.payload.partnerId),
          action.payload.partnerId
        ]
      };
    case fromTypes.ADD_ALL_PARTNERS_TO_CAMPAIGN:
      return {
        ...state,
        partnerIds: action.payload.partnerIds
      };
    case fromTypes.REMOVE_PARTNER_FROM_CAMPAIGN:
      return {
        ...state,
        partnerIds: state.partnerIds.filter(partnerId => partnerId !== action.payload.partnerId)
      };
    default:
      return state;
  }
};

export default reducer;
