// @flow
export const GET_CAMPAIGNS_REQUEST = '[GET_CAMPAIGN] GET ALL REQUEST';
export const GET_CAMPAIGN_REQUEST = '[GET_CAMPAIGN] GET BY ID REQUEST';
export const GET_CAMPAIGNS_SUCCESS = '[GET_CAMPAIGN] GET ALL SUCCESS';
export const GET_CAMPAIGN_SUCCESS = '[GET_CAMPAIGN] GET BY ID SUCCESS';

export const CREATE_CAMPAIGN_REQUEST = '[UPDATE_CAMPAIGN] CREATE REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = '[UPDATE_CAMPAIGN] CREATE SUCCESS';

export const UPDATE_CAMPAIGN_REQUEST = '[UPDATE_CAMPAIGN] UPDATE REQUEST';
export const UPDATE_CAMPAIGN_SUCCESS = '[UPDATE_CAMPAIGN] UPDATE SUCCESS';

export const APPROVE_PARTNER_CONTRACT_REQUEST = '[APPROVE_PARTNER] APPROVE REQUEST';
export const APPROVE_PARTNER_CONTRACT_SUCCESS = '[APPROVE_PARTNER] APPROVE SUCCESS';

export const UPDATE_PARTNER_CONTRACT_STATUS_REQUEST = '[APPROVE_PARTNER] UPDATE STATUS REQUEST';
export const UPDATE_PARTNER_CONTRACT_STATUS_SUCCESS = '[APPROVE_PARTNER] UPDATE STATUS SUCCESS';
export const UPDATE_PARTNER_CONTRACT_STATUS_ERROR = '[APPROVE_PARTNER] UPDATE STATUS ERROR';

export const UPDATE_CAMPAIGN_STATUS_REQUEST = '[APPROVE_PARTNER] UPDATE CAMPAIGN STATUS REQUEST';
export const UPDATE_CAMPAIGN_STATUS_SUCCESS = '[APPROVE_PARTNER] UPDATE CAMPAIGN STATUS SUCCESS';

export const ADD_PARTNER_TO_CAMPAIGN = '[ADD_PARTNER] ADD PARTNER TO CAMPAIGN';
export const ADD_ALL_PARTNERS_TO_CAMPAIGN = '[ADD_PARTNERS] ADD ALL PARTNERS TO CAMPAIGN';
export const REMOVE_PARTNER_FROM_CAMPAIGN = '[REMOVE_PARTNER] REMOVE PARTNER FROM CAMPAIGN';

type GetAllRequest = { type: typeof GET_CAMPAIGNS_REQUEST };
type GetRequest = { type: typeof GET_CAMPAIGN_REQUEST };
type GetAllSuccess = { type: typeof GET_CAMPAIGNS_SUCCESS, payload: any };
type GetSuccess = { type: typeof GET_CAMPAIGN_SUCCESS, payload: any };

type UpdateRequest = { type: typeof UPDATE_CAMPAIGN_REQUEST, payload: any };
type UpdateSuccess = { type: typeof UPDATE_CAMPAIGN_SUCCESS };

type CreateRequest = { type: typeof CREATE_CAMPAIGN_SUCCESS, payload: any };
type CreateSuccess = { type: typeof CREATE_CAMPAIGN_SUCCESS };

type ApproveRequest = { type: typeof APPROVE_PARTNER_CONTRACT_REQUEST, payload: any };
type ApproveSuccess = { type: typeof APPROVE_PARTNER_CONTRACT_SUCCESS };

type UpdateStatusRequest = { type: typeof UPDATE_PARTNER_CONTRACT_STATUS_REQUEST, payload: any };
type UpdateStatusSuccess = { type: typeof UPDATE_PARTNER_CONTRACT_STATUS_SUCCESS };
type UpdateStatusError = { type: typeof UPDATE_PARTNER_CONTRACT_STATUS_ERROR };

type UpdateCampaignStatusRequest = { type: typeof UPDATE_CAMPAIGN_STATUS_REQUEST, payload: any };
type UpdateCampaignStatusSuccess = { type: typeof UPDATE_CAMPAIGN_STATUS_SUCCESS };

type AddPartnerToCampaign = { type: typeof ADD_PARTNER_TO_CAMPAIGN };
type AddAllPartnersToCampaign = { type: typeof ADD_ALL_PARTNERS_TO_CAMPAIGN };
type removePartnerFromCampaign = { type: typeof REMOVE_PARTNER_FROM_CAMPAIGN };

export type CampaignTypes =
  | GetAllRequest
  | GetRequest
  | GetAllSuccess
  | GetSuccess
  | UpdateRequest
  | UpdateSuccess
  | CreateRequest
  | CreateSuccess
  | ApproveRequest
  | ApproveSuccess
  | UpdateStatusRequest
  | UpdateStatusSuccess
  | UpdateCampaignStatusRequest
  | UpdateCampaignStatusSuccess
  | AddPartnerToCampaign
  | AddAllPartnersToCampaign
  | UpdateStatusError
  | removePartnerFromCampaign;
